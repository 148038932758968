/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { ControllerEvent } from '../controller/event';
import { ControllerEventType } from '../controller/event-type';
import { ContextMenuCapability } from '../plugins/context-menu/context-menu.capability';
import { WidgetCapability } from '../widget/capability';
import { WidgetPortable } from '../widget/portable';
import { WidgetSite } from '../widget/site';
import './data.widget.scss';

export type DataWidgetConfig = {
  bold: boolean;
  center: boolean;
  dataKey: string;
  color: string;
  overwrite: string | null;
};
export class DataWidget extends WidgetPortable<DataWidgetConfig> {
  protected _capabilities: WidgetCapability[] = [
    new ContextMenuCapability([
      {
        key: 'edit-data',
        label: '编辑数据',
        onClick: (_e, widgetSite, _key) => {
          widgetSite.controller.emit(
            new ControllerEvent('DATASELECTSHOW'),
            (key: string) => {
              widgetSite.config.dataKey = key;
              widgetSite.refresh();
              widgetSite.controller.emit(
                new ControllerEvent(ControllerEventType.CHANGED),
                widgetSite,
              );
            },
          );
        },
      },
      {
        key: 'text-bold',
        label: '粗体',
        onClick: (_e, widgetSite, _key) => {
          widgetSite.config.bold = !widgetSite.config.bold;
          widgetSite.element.style.fontWeight = widgetSite.config.bold
            ? 'bold'
            : 'normal';
          widgetSite.controller.emit(
            new ControllerEvent(ControllerEventType.CHANGED),
            widgetSite,
          );
        },
      },
      {
        key: 'text-center',
        label: '居中',
        onClick: (_e, widgetSite, _key) => {
          widgetSite.config.center = !widgetSite.config.center;
          widgetSite.element.style.textAlign = widgetSite.config.center
            ? 'center'
            : 'left';
          widgetSite.controller.emit(
            new ControllerEvent(ControllerEventType.CHANGED),
            widgetSite,
          );
        },
      },
    ]),
  ];
  public readonly id = 'Data';
  constructor(layer: number, config: Partial<DataWidgetConfig> = {}) {
    super(
      layer,
      Object.assign(
        {
          bold: false,
          center: false,
          dataKey: '',
          overwrite: null,
          color: 'inherit',
        },
        config,
      ),
    );
  }

  protected _initContainer(): HTMLElement {
    const element = document.createElement('div');
    element.className = 'data-widget';
    return element;
  }

  private __onInput(widgetSite: WidgetSite<DataWidgetConfig>) {
    widgetSite.config.overwrite = widgetSite.element.innerText;
    widgetSite.controller.emit(
      new ControllerEvent(ControllerEventType.CHANGED),
      widgetSite,
    );
  }

  protected _initSite(widgetSite: WidgetSite<DataWidgetConfig>): void {
    widgetSite.element.style.fontWeight = widgetSite.config.bold
      ? 'bold'
      : 'normal';
    widgetSite.element.style.textAlign = widgetSite.config.center
      ? 'center'
      : 'left';
    if (this.layer === widgetSite.controller.layer) {
      widgetSite.element.contentEditable = 'true';
      widgetSite.element.addEventListener('input', () =>
        this.__onInput(widgetSite),
      );
    }
  }

  public renderSite(widgetSite: WidgetSite<DataWidgetConfig>) {
    if (this.layer > widgetSite.controller.layer) {
      widgetSite.element.innerHTML = widgetSite.config.dataKey;
    } else if (this.layer === widgetSite.controller.layer) {
      widgetSite.element.innerHTML =
        null === widgetSite.config.overwrite
          ? widgetSite.controller.getDataByKeyPath(widgetSite.config.dataKey)
          : widgetSite.config.overwrite;
    } else {
      widgetSite.element.innerHTML = widgetSite.controller.getDataByKeyPath(
        widgetSite.config.dataKey,
      );
    }
    widgetSite.element.style.color = widgetSite.config.color;
    widgetSite.element.style.fontWeight = widgetSite.config.bold
      ? 'bold'
      : 'normal';
    widgetSite.element.style.textAlign = widgetSite.config.center
      ? 'center'
      : 'left';
  }
}
