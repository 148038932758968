/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Controller } from '../../controller';
import { ControllerEvent } from '../../controller/event';
import { ControllerEventType } from '../../controller/event-type';
import { ControllerPlugin } from '../plugin.interface';
import { ContextMenuCapability } from './context-menu.capability';

export class ContextMenuBasePlugin implements ControllerPlugin {
  static tryRegister(controller: Controller<any>) {
    if (!controller.isPlugInRegistered(ContextMenuBasePlugin)) {
      controller.registerPlugin(ContextMenuBasePlugin);
    }
  }

  constructor(private readonly __controller: Controller<any>) {}

  private __maybeShown = false;
  private _globalContextMenuShowHandler = (e: Event) => {
    const widgetSite = this.__controller.findInteractivableWidgetByElement(
      e.target as HTMLElement,
    );
    const contextMenuWidget = (widgetSite?.ancestors() || []).find((v) =>
      v.portable.capabilityOwn(ContextMenuCapability),
    );

    // No menu
    if (!contextMenuWidget) {
      return;
    } else {
      e.preventDefault();
      if (this.__maybeShown) {
        this.__controller.emit(
          new ControllerEvent(ControllerEventType.CONTEXTMENUHIDE),
          e,
        );
      }
      this.__controller.emit(
        new ControllerEvent(ControllerEventType.CONTEXTMENUSHOW),
        e,
        widgetSite,
        contextMenuWidget,
      );
      this.__maybeShown = true;
    }
  };

  private _globalMouseDownHandler = (e: MouseEvent) => {
    const widgetSite = this.__controller.findWidgetByElement(
      e.target as HTMLElement,
    );
    if (widgetSite) {
      this.__controller.emit(
        new ControllerEvent(ControllerEventType.CONTEXTMENUHIDE),
        e,
      );
    }
  };

  private _globalKeyDownHandler = (e: KeyboardEvent) => {
    if ('Escape' === e.code) {
      this.__controller.emit(
        new ControllerEvent(ControllerEventType.CONTEXTMENUHIDE),
        e,
      );
    }
  };

  construct(): void {
    console.log('ContextMenuBasePlugin loaded');
    window.addEventListener('contextmenu', this._globalContextMenuShowHandler);
    window.addEventListener('mousedown', this._globalMouseDownHandler);
    window.addEventListener('keydown', this._globalKeyDownHandler);
  }

  destruct(): void {
    console.log('ContextMenuBasePlugin unloaded');
    window.removeEventListener(
      'contextmenu',
      this._globalContextMenuShowHandler,
    );
    window.removeEventListener('mousedown', this._globalMouseDownHandler);
    window.removeEventListener('keydown', this._globalKeyDownHandler);
  }
}
