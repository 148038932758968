/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import SignInPage from './pages/SignIn/Page';
import OrdersPage from './pages/Orders/Page';
import TrailOverlay from './components/Trail-Overlay/Trail-Overlay';
import DocumentBuilderPage from './pages/Document-Builder/Page';
import TemplateBuilderPage from './pages/Template-Builder/Page';
import UnifiedDesigner from './pages/Unified-Designer/Page';
import DataPage from './pages/Data/Page';
import AgentPage from './pages/Agent/Page';
import { ToastContainer, Slide } from 'react-toastify';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Navigate to="/orders" />,
    },

    // Tool
    {
      path: '/designer',
      element: <UnifiedDesigner />,
    },

    // Pages
    {
      path: '/data',
      element: <DataPage />,
    },
    {
      path: '/sign-in',
      element: <SignInPage />,
    },
    {
      path: '/orders',
      element: <OrdersPage />,
    },
    {
      path: '/document-builder',
      element: <DocumentBuilderPage />,
    },
    {
      path: '/template-builder',
      element: <TemplateBuilderPage />,
    },
    {
      path: '/agent',
      element: <AgentPage />,
    },
  ]);

  return (
    <>
      <TrailOverlay />
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
