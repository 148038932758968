/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import Cookies from 'js-cookie';
import { WidgetCreateDto } from './dto/widget-create.dto';
import { WidgetDto } from './dto/widget.dto';
import { HttpAPI } from './http.api';
export class WidgetAPI {
  static async list(): Promise<WidgetDto[]> {
    return HttpAPI.get(Cookies.get('access-token') || '', '/widget');
  }

  static async create(body: WidgetCreateDto) {
    return HttpAPI.post(Cookies.get('access-token') || '', '/widget', body, {
      'Content-Type': 'application/json',
    });
  }

  static async getById(id: number) {
    return HttpAPI.get(
      Cookies.get('access-token') || '',
      `/widget/by-id/${id}`,
    );
  }

  static async updateById(id: number, body: Partial<WidgetCreateDto>) {
    return HttpAPI.patch(
      Cookies.get('access-token') || '',
      `/widget/by-id/${id}`,
      body,
      {
        'Content-Type': 'application/json',
      },
    );
  }

  static async deleteById(id: number) {
    return HttpAPI.delete(
      Cookies.get('access-token') || '',
      `/widget/by-id/${id}`,
    );
  }
}
