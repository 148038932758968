/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import React, { useEffect } from 'react';
import Overlay from '../Overlay/Overlay';
import EventEmitter from 'eventemitter3';
import './Data-Work-Overlay.scss';
import { DatePicker, Input, Select } from 'antd';
import { WorkBackgroundDto } from '../../api/dto/work-background.dto';
import dayjs from 'dayjs';
import { WorkBackgroundAPI } from '../../api/work-background.api';

const DataWorkDetailOverlayEE = new EventEmitter();

export const DataWorkDetailOverlayController = {
  show: (workBackground: WorkBackgroundDto, onSave: () => void) =>
    DataWorkDetailOverlayEE.emit('show', workBackground, onSave),
};

export default function DataWorkDetailOverlay() {
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [workBackground, setWorkBackground] =
    React.useState<WorkBackgroundDto>();
  const onSave = React.useRef<() => void>(() => {
    return;
  });

  const handleShow = (
    workBackground: WorkBackgroundDto,
    onSaveCallback: () => void,
  ) => {
    setShowOverlay(true);
    setWorkBackground(workBackground);
    onSave.current = onSaveCallback;
  };

  useEffect(() => {
    DataWorkDetailOverlayEE.on('show', handleShow);
    return () => {
      DataWorkDetailOverlayEE.off('show', handleShow);
    };
  }, []);

  const submit = async () => {
    if (!workBackground) return;
    await WorkBackgroundAPI.updateById(workBackground.id, workBackground);
    setShowOverlay(false);
    onSave.current();
  };
  return (
    <Overlay hidden={!showOverlay}>
      <div className="data-work-detail-overlay">
        <div className="title">工作履历信息</div>
        <div className="content">
          <div className="field">
            <div className="label">证明人来源</div>
            <Input
              onInput={(e) =>
                setWorkBackground(
                  Object.assign({}, workBackground, {
                    colleagueSource: e.currentTarget.value,
                  }),
                )
              }
              value={workBackground?.colleagueSource}
            />
          </div>
          <div className="field">
            <div className="label">证明人</div>
            <Input
              onInput={(e) =>
                setWorkBackground(
                  Object.assign({}, workBackground, {
                    colleagueName: e.currentTarget.value,
                  }),
                )
              }
              value={workBackground?.colleagueName}
            />
          </div>
          <div className="field">
            <div className="label">证明人职位</div>
            <Input
              onInput={(e) =>
                setWorkBackground(
                  Object.assign({}, workBackground, {
                    colleaguePosition: e.currentTarget.value,
                  }),
                )
              }
              value={workBackground?.colleaguePosition}
            />
          </div>
          <div className="field">
            <div className="label">共事关系</div>
            <Input
              onInput={(e) =>
                setWorkBackground(
                  Object.assign({}, workBackground, {
                    colleagueRelation: e.currentTarget.value,
                  }),
                )
              }
              value={workBackground?.colleagueRelation}
            />
          </div>
          <div className="field">
            <div className="label">共事时长</div>
            <Input
              type="number"
              onInput={(e) =>
                setWorkBackground(
                  Object.assign({}, workBackground, {
                    yearsOfWorkTogether: +e.currentTarget.value,
                  }),
                )
              }
              value={workBackground?.yearsOfWorkTogether}
            />
          </div>
          <div className="field">
            <div className="label">额外说明</div>
            <Input
              onInput={(e) =>
                setWorkBackground(
                  Object.assign({}, workBackground, {
                    additionalNote: e.currentTarget.value,
                  }),
                )
              }
              value={workBackground?.additionalNote}
            />
          </div>
          <div className="field">
            <div className="label">雇主名称</div>
            <Input
              onInput={(e) =>
                setWorkBackground(
                  Object.assign({}, workBackground, {
                    employerName: e.currentTarget.value,
                  }),
                )
              }
              value={workBackground?.employerName}
            />
          </div>
          <div className="field">
            <div className="label">供职时间（开始）</div>
            <DatePicker
              allowClear={false}
              onChange={(e) =>
                setWorkBackground(
                  Object.assign({}, workBackground, {
                    start: e.toString(),
                  }),
                )
              }
              value={dayjs(workBackground?.start)}
            />
          </div>
          <div className="field">
            <div className="label">供职时间（结束）</div>
            <DatePicker
              allowClear={false}
              onChange={(e) =>
                setWorkBackground(
                  Object.assign({}, workBackground, {
                    end: e.toString(),
                  }),
                )
              }
              value={dayjs(workBackground?.end)}
            />
          </div>
          <div className="field">
            <div className="label">供职方式</div>
            <Input
              onInput={(e) =>
                setWorkBackground(
                  Object.assign({}, workBackground, {
                    employmentType: e.currentTarget.value,
                  }),
                )
              }
              value={workBackground?.employmentType}
            />
          </div>
          <div className="field">
            <div className="label">汇报对象</div>
            <Input
              onInput={(e) =>
                setWorkBackground(
                  Object.assign({}, workBackground, {
                    manager: e.currentTarget.value,
                  }),
                )
              }
              value={workBackground?.manager}
            />
          </div>
          <div className="field">
            <div className="label">汇报对象职位</div>
            <Input
              onInput={(e) =>
                setWorkBackground(
                  Object.assign({}, workBackground, {
                    managerPosition: e.currentTarget.value,
                  }),
                )
              }
              value={workBackground?.managerPosition}
            />
          </div>
          <div className="field">
            <div className="label">职位名称</div>
            <Input
              onInput={(e) =>
                setWorkBackground(
                  Object.assign({}, workBackground, {
                    position: e.currentTarget.value,
                  }),
                )
              }
              value={workBackground?.position}
            />
          </div>
          <div className="field">
            <div className="label">薪资范畴</div>
            <Input
              onInput={(e) =>
                setWorkBackground(
                  Object.assign({}, workBackground, {
                    salary: e.currentTarget.value,
                  }),
                )
              }
              value={workBackground?.salary}
            />
          </div>
          <div className="field">
            <div className="label">离职原因</div>
            <Input
              onInput={(e) =>
                setWorkBackground(
                  Object.assign({}, workBackground, {
                    resignationReason: e.currentTarget.value,
                  }),
                )
              }
              value={workBackground?.resignationReason}
            />
          </div>
          <div className="field">
            <div className="label">离职类型</div>
            <Input
              onInput={(e) =>
                setWorkBackground(
                  Object.assign({}, workBackground, {
                    resignationType: e.currentTarget.value,
                  }),
                )
              }
              value={workBackground?.resignationType}
            />
          </div>
          <div className="field">
            <div className="label">是否存在违反公司规定或纪律的行为</div>
            <Select
              onChange={(e) =>
                setWorkBackground(
                  Object.assign({}, workBackground, {
                    violateEmploymentPolicy: e,
                  }),
                )
              }
              value={workBackground?.violateEmploymentPolicy}
            >
              <Select.Option value={true}>是</Select.Option>
              <Select.Option value={false}>否</Select.Option>
            </Select>
          </div>
          <div className="field">
            <div className="label">是否发生劳动争议或仲裁</div>
            <Select
              onChange={(e) =>
                setWorkBackground(
                  Object.assign({}, workBackground, { laborDispute: e }),
                )
              }
              value={workBackground?.laborDispute}
            >
              <Select.Option value={true}>是</Select.Option>
              <Select.Option value={false}>否</Select.Option>
            </Select>
          </div>
          <div className="field">
            <div className="label">是否签订竞业禁止协议？</div>
            <Select
              onChange={(e) =>
                setWorkBackground(
                  Object.assign({}, workBackground, { nonCompeteAgreement: e }),
                )
              }
              value={workBackground?.nonCompeteAgreement}
            >
              <Select.Option value={true}>是</Select.Option>
              <Select.Option value={false}>否</Select.Option>
            </Select>
          </div>
          <div className="field">
            <div className="label">是否愿意再次雇佣/共事？</div>
            <Select
              onChange={(e) =>
                setWorkBackground(
                  Object.assign({}, workBackground, {
                    willingToWorkTogether: e,
                  }),
                )
              }
              value={workBackground?.willingToWorkTogether}
            >
              <Select.Option value={true}>是</Select.Option>
              <Select.Option value={false}>否</Select.Option>
            </Select>
          </div>
        </div>
        <div className="actions">
          <a
            href="#"
            onClick={() => setShowOverlay(false)}
            className="button cancel"
          >
            取消
          </a>
          <a href="#" onClick={submit} className="button submit">
            确认
          </a>
        </div>
      </div>
    </Overlay>
  );
}
