/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { AuthAPI } from '../../api/auth.api';
import SidebarSchedule from '../Sidebar-Schedule/Sidebar-Schedule';
import './Orders-Sidebar.scss';

export default function OrdersSidebar() {
  const user = AuthAPI.getLocalUser();
  return (
    <div className="sidebar-component">
      <div className="user">
        <div className="avatar"></div>
        <div className="info">
          <div className="name">{user?.name}</div>
          <div className="role">{user?.title}</div>
        </div>
        <div className="dropdown"></div>
      </div>
      <div className="nav">
        <div className="group">
          <a href="#" className="link panel">
            <div className="icon"></div>
            <div className="text">控制面板</div>
            <div className="counter"></div>
          </a>
          <div className="separator"></div>
          <a href="/orders" className="link orders active">
            <div className="icon"></div>
            <div className="text">我的订单</div>
            <div className="counter">+15</div>
          </a>
          <a href="#" className="link approvals">
            <div className="icon"></div>
            <div className="text">审批信息</div>
            <div className="counter">10</div>
          </a>
          <a href="#" className="link original">
            <div className="icon"></div>
            <div className="text">原始资料</div>
            <div className="counter"></div>
          </a>
        </div>
        <div className="separator"></div>
        <div className="group">
          <a href="#" className="link permission">
            <div className="icon"></div>
            <div className="text">我的权限</div>
            <div className="counter"></div>
          </a>
          <a href="#" className="link team">
            <div className="icon"></div>
            <div className="text">我的团队</div>
            <div className="counter"></div>
          </a>
          <a href="#" className="link account">
            <div className="icon"></div>
            <div className="text">我的账户</div>
            <div className="counter"></div>
          </a>
        </div>
      </div>
      <SidebarSchedule />
    </div>
  );
}
