/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { WidgetCapability } from '../widget/capability';
import { WidgetPortable } from '../widget/portable';
import { WidgetSite } from '../widget/site';
import './header.widget.scss';

export type HeaderWidgetConfig = null;
export class HeaderWidget extends WidgetPortable<HeaderWidgetConfig> {
  protected _capabilities: WidgetCapability[] = [];
  public readonly id = 'Header';
  constructor(layer: number, children: WidgetPortable<any>[] = []) {
    super(layer, null, children);
  }

  protected _initContainer(): HTMLElement {
    const element = document.createElement('td');
    element.className = 'header-widget';
    return element;
  }

  protected _initSite(): void {
    return;
  }

  public renderSite(widgetSite: WidgetSite<HeaderWidgetConfig>): void {
    widgetSite.element.innerHTML =
      '<img src="/assets/widget-header.png" class="header-logo" /><div class="heading">报告正文</div>';
    return super.renderSite(widgetSite);
  }
}
