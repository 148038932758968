/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Controller } from '../../controller';
import { ControllerEvent } from '../../controller/event';
import { ControllerEventType } from '../../controller/event-type';
import { ControllerPlugin } from '../plugin.interface';
import { CommentCapability } from './comment.capability';

export class CommentBasePlugin implements ControllerPlugin {
  static tryRegister(controller: Controller<any>) {
    if (!controller.isPlugInRegistered(CommentBasePlugin)) {
      controller.registerPlugin(CommentBasePlugin);
    }
  }

  constructor(private readonly __controller: Controller<any>) {}

  private _globalCommentHandler = (e: Event) => {
    const widgetSite = this.__controller.findWidgetByElement(
      e.target as HTMLElement,
      -1,
    );
    const commentWidget = (widgetSite?.ancestors() || []).find((v) =>
      v.portable.capabilityOwn(CommentCapability),
    );

    // Not on widget
    if (!commentWidget) {
      return;
    } else {
      e.preventDefault();
      this.__controller.emit(
        new ControllerEvent(ControllerEventType.COMMENT),
        e,
        commentWidget,
      );
    }
  };

  construct(): void {
    console.log('CommentBasePlugin loaded');
    window.addEventListener('dblclick', this._globalCommentHandler);
  }

  destruct(): void {
    console.log('CommentBasePlugin unloaded');
    window.removeEventListener('dblclick', this._globalCommentHandler);
  }
}
