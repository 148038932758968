/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import React, { useEffect } from 'react';
import Overlay from '../Overlay/Overlay';
import EventEmitter from 'eventemitter3';
import './Trail-Overlay.scss';

const TrailOverlayEE = new EventEmitter();

export const TrailOverlayController = {
  show: () => TrailOverlayEE.emit('show'),
  hide: () => TrailOverlayEE.emit('hide'),
};

export default function TrailOverlay() {
  const [showOverlay, setShowOverlay] = React.useState(false);

  const handleShow = () => {
    setShowOverlay(true);
  };

  const handleHide = () => {
    setShowOverlay(false);
  };

  useEffect(() => {
    TrailOverlayEE.on('show', handleShow);
    TrailOverlayEE.on('hide', handleHide);
    return () => {
      TrailOverlayEE.off('show', handleShow);
      TrailOverlayEE.off('hide', handleHide);
    };
  }, []);
  return (
    <Overlay hidden={!showOverlay}>
      <div className="trail-overlay">
        <div className="title">更多功能展示</div>
        <div className="image"></div>
        <div className="content">
          <div className="legend">请联系客服获取更多功能展示</div>
          <div className="contact">
            客服邮箱
            <a href="mailto: hello@txz.tech" className="mail">
              hello@txz.tech
            </a>
          </div>
        </div>
        <div className="actions">
          <a
            href="#"
            onClick={() => TrailOverlayEE.emit('hide')}
            className="button cancel"
          >
            取消
          </a>
          <a
            href="#"
            onClick={() => TrailOverlayEE.emit('hide')}
            className="button submit"
          >
            好的
          </a>
        </div>
      </div>
    </Overlay>
  );
}
