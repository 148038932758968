/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import './Page.scss';
import OrdersSidebar from '../../components/Orders-Sidebar/Orders-Sidebar';
import { OrderAPI } from '../../api/order.api';
import { OrderDto } from '../../api/dto/order.dto';
import { OriginalStatus } from '../../api/dto/original-status.enum';
import { OrderStatus } from '../../api/dto/order-status.enum';
import { TrailOverlayController } from '../../components/Trail-Overlay/Trail-Overlay';

export default function OrdersPage() {
  const [orders, setOrders] = useState<OrderDto[]>([]);
  const [statusFilter, setStatusFilter] = useState<string>('');

  const load = async () => {
    try {
      const result = await OrderAPI.page(1, 100, statusFilter);
      setOrders(result);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    document.title = '背调订单 • 背景调查智能系统';
    load();
  }, [statusFilter]);

  return (
    <div className="orders-page">
      <Header />
      <div className="body">
        <OrdersSidebar />
        <main>
          <div className="title">
            <div className="text">我的订单</div>
            <a
              href="#"
              className="add"
              onClick={() => TrailOverlayController.show()}
            ></a>
          </div>
          <div className="ribbon">
            <div className="views">
              <a
                href="#"
                className="view grid"
                onClick={() => TrailOverlayController.show()}
              >
                <div className="icon"></div>
                <div className="text">网格视图</div>
              </a>
              <a href="#" className="view gallery active">
                <div className="icon"></div>
                <div className="text">画廊视图</div>
              </a>
            </div>
            <div className="filters">
              <a
                href="#"
                className={`filter ${statusFilter === '' && 'active'}`}
                onClick={() => setStatusFilter('')}
              >
                全部订单
              </a>
              <a
                href="#"
                className={`filter ${statusFilter === OrderStatus.PENDING && 'active'}`}
                onClick={() => setStatusFilter(OrderStatus.PENDING)}
              >
                待处理
              </a>
              <a
                href="#"
                className={`filter ${statusFilter === OrderStatus.APPROVING && 'active'}`}
                onClick={() => setStatusFilter(OrderStatus.APPROVING)}
              >
                审核中
              </a>
              <a
                href="#"
                className={`filter ${statusFilter === OrderStatus.REVISING && 'active'}`}
                onClick={() => setStatusFilter(OrderStatus.REVISING)}
              >
                待修订
              </a>
              <a
                href="#"
                className={`filter ${statusFilter === OrderStatus.COMPLETED && 'active'}`}
                onClick={() => setStatusFilter(OrderStatus.COMPLETED)}
              >
                审核通过
              </a>
            </div>
            <div className="search">
              <div className="icon"></div>
              <input type="text" className="text" placeholder="搜索" />
            </div>
          </div>
          <div className="cards">
            {orders.map((order) => (
              <a
                href={`/document-builder?id=${order.reports[0].id}`}
                className="card"
                key={`order-${order.id}`}
              >
                <div className="heading">
                  <div className="name">
                    候选人：{order.candidate.identityBackground.name}
                  </div>
                  {(() => {
                    switch (order.status) {
                      case OrderStatus.PENDING:
                        return <div className="status pending">待处理</div>;
                      case OrderStatus.APPROVING:
                        return <div className="status pending">审核中</div>;
                      case OrderStatus.REVISING:
                        return <div className="status revising">待修订</div>;
                      case OrderStatus.COMPLETED:
                        return <div className="status completed">审核通过</div>;
                    }
                  })()}
                </div>
                <div className="fields">
                  <div className="field">
                    <div className="name">
                      <div className="icon"></div>
                      <div className="text">报告编号</div>
                    </div>
                    <div className="value">{order.serial}</div>
                  </div>
                  <div className="field">
                    <div className="name">
                      <div className="icon"></div>
                      <div className="text">原始资料</div>
                    </div>
                    {(() => {
                      switch (order.originalStatus) {
                        case OriginalStatus.PENDING:
                          return <div className="status pending">待上传</div>;
                        case OriginalStatus.COMPLETED:
                          return <div className="status completed">已完成</div>;
                      }
                    })()}
                  </div>
                  <div className="field date">
                    <div className="name">
                      <div className="icon"></div>
                      <div className="text">开始时间</div>
                    </div>
                    <div className="value">2024-01-01</div>
                  </div>
                  <div className="field person">
                    <div className="name">
                      <div className="icon"></div>
                      <div className="text">负责人</div>
                    </div>
                    <div className="value">{order.manager}</div>
                  </div>
                  <div className="field progress">
                    <div className="name">
                      <div className="icon"></div>
                      <div className="text">报告进程</div>
                    </div>
                    <div className="bar">
                      <div
                        className="indicator"
                        style={{ width: `${order.progress}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </main>
      </div>
    </div>
  );
}
