/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

// import { ContextMenuCapability } from '../plugins/context-menu/context-menu.capability';
import { WidgetCapability } from '../widget/capability';
import { WidgetPortable } from '../widget/portable';
import { WidgetSite } from '../widget/site';
import { TableRowWidget } from './table-row.widget';
import './table.widget.scss';

export type TableWidgetConfig = null;
export class TableWidget extends WidgetPortable<TableWidgetConfig> {
  protected _capabilities: WidgetCapability[] = [];
  public readonly id = 'Table';
  constructor(layer: number, children: WidgetPortable<unknown>[] = []) {
    super(layer, null, children);
  }

  protected _initContainer(): HTMLElement {
    const element = document.createElement('table');
    element.className = 'table-widget';
    return element;
  }

  protected _initSite(site: WidgetSite<TableWidgetConfig>): void {
    if (0 === this.initialChildren.length) {
      site.append(new TableRowWidget(this.layer));
    }
  }

  public renderSite(widgetSite: WidgetSite<TableWidgetConfig>): void {
    return super.renderSite(widgetSite);
  }
}
