/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import React from 'react';
import Overlay from '../Overlay/Overlay';
import './Template-Builder-Data-Overlay.scss';
import EventEmitter from 'eventemitter3';
import { ReportStructureDto } from '../../api/dto/report-structure.dto';
import { ReportAPI } from '../../api/report.api';

const TemplateBuilderDataOverlayEE = new EventEmitter();
export const TemplateBuilderDataOverlayController = {
  show: (onDataSelected: (k: string) => any) =>
    TemplateBuilderDataOverlayEE.emit('show', onDataSelected),
};

export default function TemplateBuilderDataOverlay() {
  const [showOverlay, setShowOverlay] = React.useState(false);
  const onDataSelectedCallback = React.useRef<(k: string) => any>();

  const handleShow = (onDataSelected: (k: string) => any) => {
    onDataSelectedCallback.current = onDataSelected;
    setShowOverlay(true);
  };

  const handleSelect = (k: string) => {
    setShowOverlay(false);
    onDataSelectedCallback.current?.(k);
  };

  React.useEffect(() => {
    TemplateBuilderDataOverlayEE.on('show', handleShow);
    load();
    return () => {
      TemplateBuilderDataOverlayEE.off('show', handleShow);
    };
  }, []);

  const [structure, setStructure] = React.useState<ReportStructureDto>();
  const load = async () => {
    const structure = await ReportAPI.getStructure();
    setStructure(structure);
  };

  const fieldMapping: Record<string, string> = {
    candidateId: '',
    company: '',
    candidate: '',
    serialNumber: '报告编号',
    version: '报告版本号',
    isFinalVersion: '最终版',
    changeLog: '修订记录',
    'package.name': '套餐名称',
    'candidate.id': '',
    'company.id': '',
    'company.name': '委托方 名称',
    'candidate.identityBackground.candidateId': '',
    'candidate.identityBackground.name': '候选人 姓名',
    'candidate.identityBackground.nameVerified': '候选人 姓名真实性',
    'candidate.identityBackground.nameVerificationNote': '候选人 姓名说明',
    'candidate.identityBackground.identityNumber': '候选人 身份证',
    'candidate.identityBackground.identityNumberVerified':
      '候选人 身份证真实性',
    'candidate.identityBackground.identityNumberVerificationNote':
      '候选人 身份证说明',
    'candidate.identityBackground.sex': '候选人 性别',
    'candidate.identityBackground.sexVerified': '候选人 性别真实性',
    'candidate.identityBackground.sexVerificationNote': '候选人 性别说明',
    'candidate.identityBackground.age': '候选人 年龄',
    'candidate.identityBackground.ageVerified': '候选人 年龄真实性',
    'candidate.identityBackground.ageVerificationNote': '候选人 年龄说明',
    'candidate.identityBackground.dateOfBirth': '候选人 生日',
    'candidate.identityBackground.dateOfBirthVerified': '候选人 生日真实性',
    'candidate.identityBackground.dateOfBirthVerificationNote':
      '候选人 生日说明',
    'candidate.identityBackground.birthPlace': '候选人 籍贯',
    'candidate.identityBackground.birthPlaceVerified': '候选人 籍贯真实性',
    'candidate.identityBackground.birthPlaceVerificationNote':
      '候选人 籍贯说明',
    'candidate.educationBackground.id': '教育背景 ID',
    'candidate.educationBackground.candidateId': '候选人 ID',
    'candidate.educationBackground.institutionName': '教育背景 学校名称',
    'candidate.educationBackground.institutionNameVerified':
      '教育背景 学校名称真实性',
    'candidate.educationBackground.institutionNameVerificationNote':
      '教育背景 学校名称说明',
    'candidate.educationBackground.doubleFirstClass': '教育背景 双一流',
    'candidate.educationBackground.doubleFirstClassVerified':
      '教育背景 双一流真实性',
    'candidate.educationBackground.doubleFirstClassVerificationNote':
      '教育背景 双一流说明',
    'candidate.educationBackground.project985': '教育背景 985',
    'candidate.educationBackground.project985Verified': '教育背景 985真实性',
    'candidate.educationBackground.project985VerificationNote':
      '教育背景 985说明',
    'candidate.educationBackground.project211': '教育背景 211',
    'candidate.educationBackground.project211Verified': '教育背景 211真实性',
    'candidate.educationBackground.project211VerificationNote':
      '教育背景 211说明',
    'candidate.educationBackground.programName': '教育背景 专业',
    'candidate.educationBackground.programNameVerified': '教育背景 专业真实性',
    'candidate.educationBackground.programNameVerificationNote':
      '教育背景 专业说明',
    'candidate.educationBackground.degreeLevel': '教育背景 层次',
    'candidate.educationBackground.degreeLevelVerified': '教育背景 层次真实性',
    'candidate.educationBackground.degreeLevelVerificationNote':
      '教育背景 层次说明',
    'candidate.educationBackground.modeOfStudy': '教育背景 形式',
    'candidate.educationBackground.modeOfStudyVerified': '教育背景 形式真实性',
    'candidate.educationBackground.modeOfStudyVerificationNote':
      '教育背景 形式说明',
    'candidate.educationBackground.degree': '教育背景 学位类型',
    'candidate.educationBackground.degreeVerified': '教育背景 学位类型真实性',
    'candidate.educationBackground.degreeVerificationNote':
      '教育背景 学位类型说明',
    'candidate.educationBackground.degreeGrantedDate': '教育背景 获得时间',
    'candidate.educationBackground.degreeGrantedDateVerified':
      '教育背景 获得时间真实性',
    'candidate.educationBackground.degreeGrantedDateVerificationNote':
      '教育背景 获得时间说明',
    'candidate.educationBackground.start': '教育背景 开始时间',
    'candidate.educationBackground.end': '教育背景 结束时间',
    'candidate.educationBackground.startEndVerified': '教育背景 起止时间真实性',
    'candidate.educationBackground.startEndVerificationNote':
      '教育背景 起止时间说明',
  };

  return (
    <Overlay
      hidden={!showOverlay}
      className="template-builder-data-overlay-mask"
      onBackClick={() => {
        setShowOverlay(false);
      }}
    >
      <div className="template-builder-data-overlay">
        <div className="search">
          <div className="icon"></div>
          <input type="text" className="text" placeholder="搜索" />
        </div>
        <div className="fields-border">
          <table className="fields">
            <tbody>
              {structure &&
                Object.keys(structure).map(
                  (v) =>
                    v === structure[v] && (
                      <tr onClick={() => handleSelect(v)} key={`field-${v}`}>
                        <td className="checkbox"></td>
                        <td>{v}</td>
                        <td>{fieldMapping[v]}</td>
                      </tr>
                    ),
                )}
              {structure &&
                Object.keys(structure.company).map((v: any) => {
                  if ('string' === typeof structure.company[v]) {
                    return (
                      fieldMapping[`company.${v}`] && (
                        <tr
                          onClick={() => handleSelect(`company.${v}`)}
                          key={`field-company-${v}`}
                        >
                          <td className="checkbox"></td>
                          <td>company.{v}</td>
                          <td>{fieldMapping[`company.${v}`]}</td>
                        </tr>
                      )
                    );
                  }

                  return '1';
                })}
              {structure &&
                Object.keys(structure.candidate).map((v: any) => {
                  if ('string' === typeof structure.candidate[v]) {
                    return (
                      fieldMapping[`candidate.${v}`] && (
                        <tr
                          onClick={() => handleSelect(`candidate.${v}`)}
                          key={`field-candidate-${v}`}
                        >
                          <td className="checkbox"></td>
                          <td>candidate.{v}</td>
                          <td>{fieldMapping[`candidate.${v}`]}</td>
                        </tr>
                      )
                    );
                  }
                  if (structure.candidate[v] instanceof Array) {
                    return structure.candidate[v].map((w: any) => {
                      return (
                        fieldMapping[`candidate.${v}.${w}`] && (
                          <tr
                            onClick={() => handleSelect(`candidate.${v}.${w}`)}
                            key={`field-candidate-${v}-${w}`}
                          >
                            <td className="checkbox"></td>
                            <td>
                              candidate.{v}.{w}
                            </td>
                            <td>{fieldMapping[`candidate.${v}.${w}`]}</td>
                          </tr>
                        )
                      );
                    });
                  }

                  return '1';
                })}
            </tbody>
          </table>
        </div>
      </div>
    </Overlay>
  );
}
