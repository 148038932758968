/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Controller } from '../../controller';
import { ControllerEvent } from '../../controller/event';
import { ControllerEventType } from '../../controller/event-type';
import { UtilityDOM } from '../../utility/dom';
import { ControllerPlugin } from '../plugin.interface';

export class DragOverlayPlugin implements ControllerPlugin {
  static tryRegister(controller: Controller<any>) {
    if (!controller.isPlugInRegistered(DragOverlayPlugin)) {
      controller.registerPlugin(DragOverlayPlugin);
    }
  }

  constructor(private readonly __controller: Controller<any>) {}

  private _onDrag = (
    _controllerEvent: ControllerEvent,
    initialMouseEvent: MouseEvent,
  ) => {
    const indicator = UtilityDOM.boggart(
      initialMouseEvent.target as HTMLElement,
    );
    indicator.style.backdropFilter = 'blur(5px)';
    indicator.style.backgroundColor = indicator.style.backgroundColor.replace(
      /^rgb\((\d+),\s?(\d+),\s?(\d+)\)$/,
      'rgba($1,$2,$3,0.3)',
    );
    indicator.style.zIndex = '9';
    this.__controller.overlayAdd(indicator);

    const dragging = (
      _controllerEvent: ControllerEvent,
      mouseEvent: MouseEvent,
    ) => {
      indicator.style.left = `${mouseEvent.x - initialMouseEvent.offsetX}px`;
      indicator.style.top = `${mouseEvent.y - initialMouseEvent.offsetY}px`;
    };
    const drop = () => {
      this.__controller.overlayRemove(indicator);
      this.__controller.off(ControllerEventType.DRAGGING, dragging);
      this.__controller.off(ControllerEventType.DROP, drop);
    };

    this.__controller.on(ControllerEventType.DRAGGING, dragging);
    this.__controller.on(ControllerEventType.DROP, drop);
  };

  public construct(): void {
    this.__controller.on(ControllerEventType.DRAG, this._onDrag);
  }

  public destruct(): void {
    this.__controller.off(ControllerEventType.DRAG, this._onDrag);
  }
}
