/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Controller } from '../controller';
import { ControllerEvent } from '../controller/event';
import { ControllerEventType } from '../controller/event-type';
import { ContextMenuCapability } from '../plugins/context-menu/context-menu.capability';
import { WidgetCapability } from '../widget/capability';
import { WidgetDump } from '../widget/dump';
import { WidgetPortable } from '../widget/portable';
import { WidgetSite } from '../widget/site';
import { DumpWrapperWidget } from './dump-wrapper.widget';
import './each.widget.scss';

export type EachWidgetConfig = {
  dataKey: string;
};
export class EachWidget extends WidgetPortable<EachWidgetConfig> {
  protected _capabilities: WidgetCapability[] = [
    new ContextMenuCapability([
      {
        key: 'edit-data',
        label: '编辑循环绑定',
        onClick: (_e, widgetSite, _key) => {
          widgetSite.controller.emit(
            new ControllerEvent('DATASELECTSHOW'),
            (key: string) => {
              widgetSite.config.dataKey = key;
              widgetSite.refresh();
              widgetSite.controller.emit(
                new ControllerEvent(ControllerEventType.CHANGED),
                widgetSite,
              );
            },
          );
        },
      },
    ]),
  ];
  public readonly id: string = 'Each';
  constructor(layer: number, config: Partial<EachWidgetConfig> = {}) {
    super(layer, Object.assign({ dataKey: '' }, config));
  }

  protected _initContainer(): HTMLElement {
    const containerElement = document.createElement('div');
    containerElement.className = 'each-widget';
    return containerElement;
  }

  protected _initSite(_widgetSite: WidgetSite<EachWidgetConfig>): void {
    return;
  }

  public renderSite(widgetSite: EachWidgetSite) {
    if (this.layer >= widgetSite.controller.layer) {
      return super.renderSite(widgetSite);
    }

    const array = widgetSite.controller.getDataByKeyPath(
      widgetSite.config.dataKey,
    );
    if (!(array instanceof Array)) return;
    widgetSite.shadowChildren = [];

    for (const item of array) {
      widgetSite.controller.useTemporaryData('loop', item, () => {
        // Convert to a common layout
        const dumpLayer = new DumpWrapperWidget(this.layer, {
          dump: {
            id: '',
            children: widgetSite.children.map((v) => v.toJSON()),
            config: null,
            layer: this.layer,
            portable: 'Column-Layout',
          },
        });
        const loopSite = dumpLayer.site(widgetSite, widgetSite.controller);
        widgetSite.shadowChildren.push(loopSite);
        widgetSite.element.appendChild(loopSite.element);
      });
    }
    return;
  }

  public site(
    parent: WidgetSite<any> | null,
    controller: Controller<any>,
    id: string | null = null,
  ): WidgetSite<EachWidgetConfig> {
    const site = new EachWidgetSite(
      controller,
      id || Math.random().toString(36),
      this,
      parent,
      JSON.parse(JSON.stringify(this.initialConfig)),
      this._initContainer(),
      this.initialChildren,
    );
    this._initSite(site);
    site.refresh();
    return site;
  }
}

export class EachWidgetSite extends WidgetSite<EachWidgetConfig> {
  public shadowChildren: WidgetSite<any>[] = [];

  public findWidgetByElement(
    element: HTMLElement,
    layer: number,
  ): WidgetSite<any> | null {
    if (this.element === element) {
      return layer <= this.portable.layer ? this : null;
    }
    for (const child of this.shadowChildren) {
      const result = child.findWidgetByElement(element, layer);
      if (result) return result;
    }
    return null;
  }

  public findWidgetById(id: string): WidgetSite<any> | null {
    if (this.id === id) {
      return this;
    }
    for (const child of this.shadowChildren) {
      const result = child.findWidgetById(id);
      if (result) return result;
    }
    return null;
  }

  public toJSON(): WidgetDump {
    return {
      id: this.id,
      portable: this.portable.id,
      layer: this.portable.layer,
      config: this.config,
      children: this.children.map((v) => v.toJSON()),
    };
  }
}
