/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Controller } from '../../controller';
import { ControllerEvent } from '../../controller/event';
import { ControllerEventType } from '../../controller/event-type';
import { ControllerPlugin } from '../plugin.interface';
import './pin.plugin.scss';

export class CommentPinPlugin implements ControllerPlugin {
  static tryRegister(controller: Controller<any>, className?: string) {
    if (!controller.isPlugInRegistered(CommentPinPlugin)) {
      controller.registerPlugin(CommentPinPlugin, className);
    }
  }

  constructor(
    private readonly __controller: Controller<any>,
    private readonly __className?: string,
  ) {}

  construct(): void {
    console.log('CommentPinPlugin loaded');
    this.__controller.on(ControllerEventType.RESIZE, this.__render);
    this.__controller.on(ControllerEventType.SCROLL, this.__render);
    this.__controller.on(ControllerEventType.CHANGED, this.__render);
  }

  destruct(): void {
    console.log('CommentPinPlugin unloaded');
    this.__controller.off(ControllerEventType.RESIZE, this.__render);
    this.__controller.off(ControllerEventType.SCROLL, this.__render);
    this.__controller.off(ControllerEventType.CHANGED, this.__render);
  }

  private __pins: Record<string, HTMLAnchorElement> = {};
  private __boundKeys: string[] = [];

  load(boundKeys: string[]): void {
    this.unload();
    this.__boundKeys = boundKeys;
    this.__render();
  }

  __render = () => {
    const rootRect = this.__controller.rootContainer?.getBoundingClientRect();
    if (!rootRect) return;
    for (const boundKey of this.__boundKeys) {
      const widgetSite = this.__controller.findWidgetById(boundKey);
      const rect = widgetSite?.element.getBoundingClientRect();
      if (!rect) {
        if (this.__pins[boundKey]) {
          this.__controller.overlayRemove(this.__pins[boundKey]);
          delete this.__pins[boundKey];
        }
        continue;
      }
      if (!this.__pins[boundKey]) {
        const linkElement = document.createElement('a');
        linkElement.className = `comment-pin-plugin-pin ${this.__className}`;
        linkElement.addEventListener('click', (e) =>
          this.__controller.emit(
            new ControllerEvent(ControllerEventType.COMMENTPINCLICK),
            e,
            widgetSite,
          ),
        );
        this.__pins[boundKey] = linkElement;
        this.__controller.overlayAdd(linkElement);
      }

      // If the widget is out of the viewport, hide the pin
      this.__pins[boundKey].style.display =
        rect.top < rootRect.top ||
        rect.bottom > rootRect.bottom ||
        rect.left < rootRect.left ||
        rect.right > rootRect.right
          ? 'none'
          : 'block';

      this.__pins[boundKey].style.left = `${rect.right}px`;
      this.__pins[boundKey].style.top = `${rect.top}px`;
    }
  };

  unload(): void {
    Object.values(this.__pins).forEach((v) =>
      this.__controller.overlayRemove(v),
    );
    this.__pins = {};
    this.__boundKeys = [];
  }
}
