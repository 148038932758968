/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Controller } from '../../controller';
import { ControllerBoundary } from '../../controller/boundary';
import { ControllerEvent } from '../../controller/event';
import { ControllerEventType } from '../../controller/event-type';
import { WidgetPortable } from '../../widget/portable';
import { WidgetSite } from '../../widget/site';
import { ControllerPlugin } from '../plugin.interface';
import { DropCapability } from './drop.capability';

export class DragBasePlugin implements ControllerPlugin {
  static tryRegister(controller: Controller<any>) {
    if (!controller.isPlugInRegistered(DragBasePlugin)) {
      controller.registerPlugin(DragBasePlugin);
    }
  }

  constructor(private readonly __controller: Controller<any>) {}

  construct(): void {
    console.log('DragBasePlugin loaded');
    window.addEventListener('mousemove', this._globalMouseMoveHandler);
    window.addEventListener('mouseup', this._globalMouseUpHandler);
  }

  destruct(): void {
    console.log('DragBasePlugin unloaded');
    window.removeEventListener('mousemove', this._globalMouseMoveHandler);
    window.removeEventListener('mouseup', this._globalMouseUpHandler);
  }

  private __dragging: WidgetPortable<unknown> | null = null;
  private __lastDroppableWidget: WidgetSite<any> | null = null;
  private __lastBoundaryWidget: WidgetSite<any> | null = null;
  private __lastBoundary: ControllerBoundary = ControllerBoundary.INSIDE;
  public drag(widgetPortable: WidgetPortable<unknown>, mouseEvent: MouseEvent) {
    if (this.__dragging) {
      return;
    }
    this.__dragging = widgetPortable;
    this.__controller.emit(
      new ControllerEvent(ControllerEventType.DRAG),
      mouseEvent,
      widgetPortable,
    );
  }

  private _globalMouseMoveHandler = (e: MouseEvent) => {
    if (!this.__dragging) {
      return;
    }
    this.__controller.emit(
      new ControllerEvent(ControllerEventType.DRAGGING),
      e,
      this.__dragging,
    );

    // Drag over
    const ancestors =
      this.__controller
        .findWidgetByElement(e.target as HTMLElement, this.__controller.layer)
        ?.ancestors() || [];

    const droppableWidgetIndex = ancestors.findIndex((v) =>
      v.portable.capabilityOwn(DropCapability),
    );

    let boundaryWidget =
      -1 === droppableWidgetIndex ? null : ancestors[droppableWidgetIndex - 1];

    const droppableWidget = ancestors[droppableWidgetIndex] || null;

    let boundary: ControllerBoundary = ControllerBoundary.INSIDE;

    // Need to find out the boundary if drop point is valid but no boundary
    // TODO: X Axis
    if (droppableWidget && !boundaryWidget) {
      const children = droppableWidget.children;
      let closestChild: WidgetSite<any> | null = null,
        closestBoundary: ControllerBoundary | null = null,
        closestDistance = Infinity;
      for (const child of children) {
        const childRect = child.element.getBoundingClientRect();
        const topDistance = Math.abs(childRect.top - e.clientY);
        const bottomDistance = Math.abs(childRect.bottom - e.clientY);
        const minYDistance = Math.min(topDistance, bottomDistance);
        if (closestDistance < minYDistance) continue;
        closestDistance = minYDistance;
        closestChild = child;
        closestBoundary =
          bottomDistance > topDistance
            ? ControllerBoundary.TOP
            : ControllerBoundary.BOTTOM;
      }

      if (closestChild && closestBoundary) {
        boundaryWidget = closestChild;
        boundary = closestBoundary;
      }
    } else if (boundaryWidget) {
      const rect = boundaryWidget?.element.getBoundingClientRect() || null;

      const verticalRelativePosition = rect
        ? ((e.clientY - rect.top) / rect.height) * 100
        : 0;

      boundary = boundaryWidget
        ? verticalRelativePosition < 50
          ? ControllerBoundary.TOP
          : ControllerBoundary.BOTTOM
        : ControllerBoundary.INSIDE;
    }

    // Nothing changed
    if (
      this.__lastDroppableWidget === droppableWidget &&
      this.__lastBoundaryWidget === boundaryWidget &&
      this.__lastBoundary === boundary
    ) {
      return;
    }

    // Emit drag-out
    if (null !== this.__lastDroppableWidget) {
      this.__controller.emit(
        new ControllerEvent(ControllerEventType.DRAGOUT),
        e,
        this.__dragging,
        this.__lastDroppableWidget,
        this.__lastBoundaryWidget,
      );
    }
    this.__lastDroppableWidget = droppableWidget;
    this.__lastBoundaryWidget = boundaryWidget;
    this.__lastBoundary = boundary;

    if (null === this.__lastDroppableWidget) return;

    this.__controller.emit(
      new ControllerEvent(ControllerEventType.DRAGOVER),
      e,
      this.__dragging,
      this.__lastDroppableWidget,
      this.__lastBoundaryWidget,
      boundary,
    );
  };

  private _globalMouseUpHandler = (e: MouseEvent) => {
    if (!this.__dragging) {
      return;
    }
    if (
      null !== this.__lastDroppableWidget ||
      null !== this.__lastBoundaryWidget
    ) {
      this.__controller.emit(
        new ControllerEvent(ControllerEventType.DRAGOUT),
        e,
        this.__dragging,
        this.__lastDroppableWidget,
        this.__lastBoundaryWidget,
      );
    }
    this.__lastDroppableWidget?.portable
      .capabilityGet(DropCapability)
      ?.onDrop(
        this.__dragging,
        this.__lastDroppableWidget,
        this.__lastBoundary,
        this.__lastBoundaryWidget,
      );
    this.__controller.emit(
      new ControllerEvent(ControllerEventType.DROP),
      e,
      this.__dragging,
      this.__lastDroppableWidget,
      this.__lastBoundary,
      this.__lastBoundaryWidget,
    );
    this.__lastDroppableWidget = null;
    this.__lastBoundaryWidget = null;
    this.__lastBoundary = ControllerBoundary.INSIDE;
    this.__dragging = null;
  };
}
