/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import React from 'react';
import './Sidebar-Schedule.scss';
import dayjs from 'dayjs';

export default function SidebarSchedule() {
  return (
    <div className="sidebar-schedule">
      <div className="calendar">
        <div className="date">
          <div className="month">{dayjs().format('MMM')}</div>
          <div className="date">{dayjs().format('DD')}</div>
        </div>
        <div className="events">
          <div className="upcoming">
            <div className="info">Jenny / 会议室05</div>
            <div className="duration">14:00-15:00</div>
            <div className="time">In 30 min</div>
          </div>
          <div className="next">项目审核</div>
        </div>
      </div>
      <div className="actions">
        <div className="tab calendar"></div>
        <div className="tab time"></div>
        <div className="tab task"></div>
        <div className="add"></div>
      </div>
    </div>
  );
}
