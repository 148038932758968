/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { ControllerBoundary } from '../../controller/boundary';
import { WidgetCapability } from '../../widget/capability';
import { WidgetPortable } from '../../widget/portable';
import { WidgetSite } from '../../widget/site';

export class DropCapability extends WidgetCapability {
  constructor(
    public readonly onDrop: (
      widgetPortable: WidgetPortable<unknown>,
      droppableWidgetSite: WidgetSite<any>,
      boundary: ControllerBoundary | null,
      boundaryWidgetSite: WidgetSite<any> | null,
    ) => void,
    public readonly boundaries: ControllerBoundary[] = [
      ControllerBoundary.INSIDE,
    ],
  ) {
    super();
  }
}
