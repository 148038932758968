/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import React, { useRef, useState } from 'react';
import Header from '../../components/Header/Header';
import './Page.scss';
import DocumentBuilderLibrary from '../../components/Document-Builder-Library/Document-Builder-Library';
import { Controller } from '../Unified-Designer/lib/controller';
import { ColumnLayoutWidget } from '../Unified-Designer/lib/widgets/column-layout.widget';
import { ContextMenuBasePlugin } from '../Unified-Designer/lib/plugins/context-menu/base.plugin';
import { ContextMenuDefaultImplementationPlugin } from '../Unified-Designer/lib/plugins/context-menu/default-implementation.plugin';
import { ContextMenuIndicatorPlugin } from '../Unified-Designer/lib/plugins/context-menu/indicator.plugin';
import { DragBasePlugin } from '../Unified-Designer/lib/plugins/drag-drop/base.plugin';
import { DragIndicatorPlugin } from '../Unified-Designer/lib/plugins/drag-drop/indicator.plugin';
import { DragOverlayPlugin } from '../Unified-Designer/lib/plugins/drag-drop/overlay.plugin';
import { ReportAPI } from '../../api/report.api';
import { ReportDto } from '../../api/dto/report.dto';
import { ControllerEventType } from '../Unified-Designer/lib/controller/event-type';
import { WidgetDump } from '../Unified-Designer/lib/widget/dump';
import { CommentBasePlugin } from '../Unified-Designer/lib/plugins/comment/base.plugin';
import { ControllerEvent } from '../Unified-Designer/lib/controller/event';
import { WidgetSite } from '../Unified-Designer/lib/widget/site';
import { ReportCommentAPI } from '../../api/report-comment.api';
import DocumentBuilderCommentOverlay, {
  DocumentBuilderCommentOverlayController,
} from '../../components/Document-Builder-Comment-Overlay/Document-Builder-Comment-Overlay';
import { ReportCommentDto } from '../../api/dto/report-comment.dto';
import { HeaderWidget } from '../Unified-Designer/lib/widgets/header.widget';
import { CommentPinPlugin } from '../Unified-Designer/lib/plugins/comment/pin.plugin';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TrailOverlayController } from '../../components/Trail-Overlay/Trail-Overlay';

export default function DocumentBuilderPage() {
  const [showOriginal, setShowOriginal] = useState(false);
  const [controller] = React.useState<Controller<null>>(
    new Controller(new ColumnLayoutWidget(1), 1),
  );

  const [searchParams] = useSearchParams();

  const [rootContainer, setRootContainer] = React.useState<HTMLElement>();
  const [report, setReport] = React.useState<ReportDto>();
  const [reports, setReports] = React.useState<ReportDto[]>();

  React.useEffect(() => {
    document.title = '报告制作 • 背景调查智能系统';
    if (!rootContainer) return;
    controller.construct(rootContainer);
    return () => {
      controller.destruct();
    };
  }, [rootContainer]);

  React.useEffect(() => {
    if (!controller) return;
    // Plug-Ins
    DragBasePlugin.tryRegister(controller);
    DragOverlayPlugin.tryRegister(controller);
    DragIndicatorPlugin.tryRegister(controller);
    ContextMenuBasePlugin.tryRegister(controller);
    ContextMenuIndicatorPlugin.tryRegister(controller);
    ContextMenuDefaultImplementationPlugin.tryRegister(controller);
    CommentBasePlugin.tryRegister(controller);
    CommentPinPlugin.tryRegister(controller, 'comment-pin');
  }, [controller]);

  const save = async () => {
    if (!controller || !report) return;
    const dump = controller.dump();
    toast.promise(
      ReportAPI.updateById(report.id, {
        rawContent: JSON.parse(JSON.stringify(dump)),
      }),
      {
        pending: '自动保存中',
        success: '',
        error: '自动保存失败',
      },
    );
  };

  React.useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const reports = await ReportAPI.list();
    setReports(reports);
    if (0 !== reports.length) {
      if (searchParams.has('id')) selectReport(searchParams.get('id') || '');
      else selectReport(reports[0].id);
    }
  };

  const selectReport = async (id: string) => {
    const report = await ReportAPI.getById(id);
    setReport({
      ...report,
      candidate: {
        ...report.order.candidate,
        educationBackground: report.order.candidate
          .educationBackground[0] as any,
      },
      package: {
        ...report.order.package,
      },
      company: {
        ...report.order.company,
      },
    });
  };

  const rawComments = useRef<Record<string, ReportCommentDto[]>>({});

  const showCommentEventHandler = (
    _e: ControllerEvent,
    m: MouseEvent,
    target: WidgetSite<any>,
  ) => {
    showComment(target);
    m.stopPropagation();
  };

  const showComment = (target: WidgetSite<any>) => {
    if (!report) return;
    const reload = async () => {
      await loadComment();
      showComment(target);
    };
    DocumentBuilderCommentOverlayController.show(
      report.id,
      target.id,
      rawComments.current[target.id] || [],
      target.element.getBoundingClientRect(),
      reload,
    );
  };

  const hideComment = () => {
    DocumentBuilderCommentOverlayController.hide();
  };

  const loadComment = async () => {
    if (!report) return;
    rawComments.current = (
      await ReportCommentAPI.listByReport(report.id)
    ).reduce(
      (acc, cur) => {
        acc[cur.bindId] = acc[cur.bindId] || [];
        acc[cur.bindId].push(cur);
        return acc;
      },
      {} as Record<string, ReportCommentDto[]>,
    );
    controller
      .getPlugIn(CommentPinPlugin)
      ?.load(Object.keys(rawComments.current));
  };

  React.useEffect(() => {
    if (report && controller) {
      controller.setData(report);
      if (report.rawContent) {
        controller.restore(report.rawContent as WidgetDump);
        loadComment();
      } else {
        controller.root.clear();
        controller.root.append(new HeaderWidget(1));
        rawComments.current = {};
      }
    }

    let timeOut: any = 0;
    const onChange = () => {
      clearTimeout(timeOut);
      timeOut = setTimeout(() => save(), 3000);
    };

    controller.on(ControllerEventType.CHANGED, onChange);
    controller.on(ControllerEventType.COMMENT, showCommentEventHandler);
    controller.on(ControllerEventType.COMMENTPINCLICK, showCommentEventHandler);
    return () => {
      controller.off(ControllerEventType.CHANGED, onChange);
      controller.off(ControllerEventType.COMMENT, showCommentEventHandler);
      controller.off(
        ControllerEventType.COMMENTPINCLICK,
        showCommentEventHandler,
      );
    };
  }, [report]);

  const download = () => {
    if (!rootContainer) return;

    const { jsPDF } = (window as any)['jspdf'];
    const pdf = new jsPDF('p', 'pt', 'a4');
    pdf.addFont('SourceHanSans-Normal.ttf', 'SourceHanSans-Normal', 'normal');
    pdf.addFont('SourceHanSans-Bold.ttf', 'SourceHanSans-Bold', 'bold');
    pdf.setFont('SourceHanSans-Normal');
    rootContainer.style.fontFamily = "'SourceHanSans-Normal', sans-serif";

    pdf
      .html(rootContainer, { html2canvas: { scale: 0.8 }, x: 22, y: 50 })
      .save(
        `${report?.candidate.identityBackground.name}-${report?.company.name}-背景信息调查报告.pdf`,
      );
  };

  return (
    <div className="document-builder-page" onClick={() => hideComment()}>
      <Header />
      <DocumentBuilderCommentOverlay />
      <div className={`body ${showOriginal && 'unfold'}`}>
        <DocumentBuilderLibrary
          controller={controller}
          report={report}
          onReview={() => report && selectReport(report.id)}
          onAccept={() => report && selectReport(report.id)}
          onDownload={download}
        />
        <div className="content editor">
          <div className="title">
            <div className="legend">
              <div className="text">报告制作</div>
              <a
                href="#"
                className="add"
                onClick={() => TrailOverlayController.show()}
              ></a>
            </div>
            <a
              href="#"
              className="primary unfold"
              onClick={() => setShowOriginal(true)}
            ></a>
          </div>
          <div className="ribbon">
            <div className="tabbar">
              <div className="tabs">
                {reports?.map((v) => (
                  <div
                    className={`tab ${v.id === report?.id && 'active'}`}
                    key={`report-${v.id}`}
                    onClick={() => selectReport(v.id)}
                  >
                    {v.order.candidate.identityBackground.name}
                    <a href="#" className="close"></a>
                  </div>
                ))}
              </div>
              <div className="zoom">
                <div className="value">100%</div>
                <div className="actions">
                  <a href="#" className="action in"></a>
                  <a href="#" className="action out"></a>
                </div>
              </div>
            </div>
            <a href="#" className="extra"></a>
          </div>
          <div className="preview" ref={(r) => r && setRootContainer(r)}>
            {/* <div className="placeholder">
              <div className="icon"></div>
              <div className="text">立刻开始你的报告制作～</div>
            </div> */}
          </div>
        </div>
        <div
          className="content original"
          onTransitionEnd={() =>
            controller.emit(new ControllerEvent(ControllerEventType.RESIZE))
          }
        >
          <div className="title">
            <div className="legend">
              <div className="text">原始资料</div>
              <a href="#" className="add"></a>
            </div>
            <a
              href="#"
              className="primary fold"
              onClick={() => setShowOriginal(false)}
            ></a>
          </div>
          <div className="ribbon">
            <div className="name">
              {report?.candidate.identityBackground.name}
            </div>
            <div className="tabbar">
              <div className="tabs">
                <div
                  className="tab"
                  onClick={() => TrailOverlayController.show()}
                >
                  工作履历访谈报告
                </div>
                <div
                  className="tab"
                  onClick={() => TrailOverlayController.show()}
                >
                  风险提示报告
                </div>
                <div
                  className="tab"
                  onClick={() => TrailOverlayController.show()}
                >
                  学历调查报告
                </div>
                <div className="tab active">录音文件</div>
              </div>
              <div className="zoom">
                <div className="value">100%</div>
                <div className="actions">
                  <a href="#" className="action in"></a>
                  <a href="#" className="action out"></a>
                </div>
              </div>
            </div>
          </div>
          <div className="list">
            <div className="item audio">
              <div className="no">01</div>
              <div className="icon"></div>
              <div className="label">
                <div className="name">初审访谈_张伟_20240722</div>
                <div className="date">2024-07-22</div>
              </div>
              <div className="size">25:51</div>
            </div>
            <div className="item audio">
              <div className="no">02</div>
              <div className="icon"></div>
              <div className="label">
                <div className="name">核实记录_李娜_20240723</div>
                <div className="date">2024-07-22</div>
              </div>
              <div className="size">20:51</div>
            </div>
            <div className="item audio">
              <div className="no">03</div>
              <div className="icon"></div>
              <div className="label">
                <div className="name">初审访谈_张伟_20240722</div>
                <div className="date">2024-07-22</div>
              </div>
              <div className="size">25:51</div>
            </div>
            <div className="item audio">
              <div className="no">04</div>
              <div className="icon"></div>
              <div className="label">
                <div className="name">初审访谈_张伟_20240722</div>
                <div className="date">2024-07-22</div>
              </div>
              <div className="size">15:51</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
