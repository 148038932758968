/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import React from 'react';
import Header from '../../components/Header/Header';
import TemplateSidebar from '../../components/Template-Sidebar/Template-Sidebar';
import './Page.scss';
import { WidgetAPI } from '../../api/widget.api';
import { Controller } from '../Unified-Designer/lib/controller';
import { ColumnLayoutWidget } from '../Unified-Designer/lib/widgets/column-layout.widget';
import { DragBasePlugin } from '../Unified-Designer/lib/plugins/drag-drop/base.plugin';
import { ContextMenuBasePlugin } from '../Unified-Designer/lib/plugins/context-menu/base.plugin';
import { ContextMenuDefaultImplementationPlugin } from '../Unified-Designer/lib/plugins/context-menu/default-implementation.plugin';
import { ContextMenuIndicatorPlugin } from '../Unified-Designer/lib/plugins/context-menu/indicator.plugin';
import { DragIndicatorPlugin } from '../Unified-Designer/lib/plugins/drag-drop/indicator.plugin';
import { DragOverlayPlugin } from '../Unified-Designer/lib/plugins/drag-drop/overlay.plugin';
import { WidgetDto } from '../../api/dto/widget.dto';
import TemplateBuilderDataOverlay, {
  TemplateBuilderDataOverlayController,
} from '../../components/Template-Builder-Data-Overlay/Template-Builder-Data-Overlay';
import { ControllerEvent } from '../Unified-Designer/lib/controller/event';
import { TrailOverlayController } from '../../components/Trail-Overlay/Trail-Overlay';
// import { TableWidget } from '../Unified-Designer/lib/widgets/table.widget';
// import { TextWidget } from '../Unified-Designer/lib/widgets/text.widget';
// import { EachWidget } from '../Unified-Designer/lib/widgets/each.widget';
// import { RowLayoutWidget } from '../Unified-Designer/lib/widgets/row-layout.widget';
// import { DataWidget } from '../Unified-Designer/lib/widgets/data.widget';
// import { FlagIconWidget } from '../Unified-Designer/lib/widgets/flag-icon.widget';

export default function TemplateBuilderPage() {
  const [widgets, setWidgets] = React.useState<
    { name: string; widgets: WidgetDto[] }[]
  >([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [controller, setController] = React.useState(
    new Controller(new ColumnLayoutWidget(0), 0),
  );
  const [rootContainer, setRootContainer] = React.useState<HTMLElement>();

  React.useEffect(() => {
    if (!rootContainer) return;
    controller.construct(rootContainer);
    return () => {
      controller.destruct();
    };
  }, [rootContainer]);

  React.useEffect(() => {
    // Plug-Ins
    DragBasePlugin.tryRegister(controller);
    DragOverlayPlugin.tryRegister(controller);
    DragIndicatorPlugin.tryRegister(controller);
    ContextMenuBasePlugin.tryRegister(controller);
    ContextMenuIndicatorPlugin.tryRegister(controller);
    ContextMenuDefaultImplementationPlugin.tryRegister(controller);
    const onDataSelectShow = (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      e: ControllerEvent,
      onDataSelected: (k: string) => any,
    ) => {
      TemplateBuilderDataOverlayController.show(onDataSelected);
      return;
    };
    controller.on('DATASELECTSHOW', onDataSelectShow);
    return () => {
      controller.off('DATASELECTSHOW', onDataSelectShow);
    };
  }, [controller]);

  const [selectedWidget, setSelectedWidget] = React.useState<WidgetDto>();
  React.useEffect(() => {
    document.title = '报告模版 • 背景调查智能系统';
    if (selectedWidget) {
      if (0 < Object.keys(selectedWidget.definition).length) {
        controller.restore(selectedWidget.definition);
      } else {
        controller.root.clear();
      }
    }
  }, [selectedWidget]);

  const load = async () => {
    const widgets: { name: string; widgets: WidgetDto[] }[] = [];
    const res = await WidgetAPI.list();

    res.map((w) => {
      if (-1 === widgets.findIndex((v) => v.name === w.group)) {
        widgets.push({ name: w.group, widgets: [] });
      }

      widgets
        .find((v) => v.name === w.group)
        ?.widgets.push({
          id: w.id,
          name: w.name,
          icon: w.icon,
          definition: w.definition,
          group: w.group,
        });
      setWidgets(widgets);
    });
  };

  React.useEffect(() => {
    load();
  }, []);

  const save = async () => {
    if (!selectedWidget) return;
    WidgetAPI.updateById(selectedWidget.id, {
      definition: controller.dump(),
    });
  };

  return (
    <div className="template-builder-page">
      <Header />
      <TemplateBuilderDataOverlay />
      <div className="body">
        <TemplateSidebar />
        <main>
          <div className="title">
            <div className="text">初始模版</div>
          </div>
          {/* <div className="floating-widgets">
            <div
              className="widget"
              style={{
                width: '10rem',
                lineHeight: '2rem',
                background: '#eee',
                borderRadius: '1rem',
              }}
              onMouseDown={(e) =>
                controller
                  .getPlugIn(DragBasePlugin)
                  ?.drag(new RowLayoutWidget(0), e.nativeEvent)
              }
            >
              Row
            </div>
            <div
              className="widget"
              style={{
                width: '10rem',
                lineHeight: '2rem',
                background: '#eee',
                borderRadius: '1rem',
              }}
              onMouseDown={(e) =>
                controller
                  .getPlugIn(DragBasePlugin)
                  ?.drag(new TableWidget(0), e.nativeEvent)
              }
            >
              Table
            </div>
            <div
              className="widget"
              style={{
                width: '10rem',
                lineHeight: '2rem',
                background: '#eee',
                borderRadius: '1rem',
              }}
              onMouseDown={(e) =>
                controller
                  .getPlugIn(DragBasePlugin)
                  ?.drag(new TextWidget(0), e.nativeEvent)
              }
            >
              Text
            </div>
            <div
              className="widget"
              style={{
                width: '10rem',
                lineHeight: '2rem',
                background: '#eee',
                borderRadius: '1rem',
              }}
              onMouseDown={(e) =>
                controller
                  .getPlugIn(DragBasePlugin)
                  ?.drag(new DataWidget(1), e.nativeEvent)
              }
            >
              Data
            </div>
            <div
              className="widget"
              style={{
                width: '10rem',
                lineHeight: '2rem',
                background: '#eee',
                borderRadius: '1rem',
              }}
              onMouseDown={(e) =>
                controller
                  .getPlugIn(DragBasePlugin)
                  ?.drag(new FlagIconWidget(1), e.nativeEvent)
              }
            >
              FlagIcon
            </div>
            <div
              className="widget"
              style={{
                width: '10rem',
                lineHeight: '2rem',
                background: '#eee',
                borderRadius: '1rem',
              }}
              onMouseDown={(e) =>
                controller
                  .getPlugIn(DragBasePlugin)
                  ?.drag(new TextWidget(1), e.nativeEvent)
              }
            >
              EditableText
            </div>
            <div
              className="widget"
              style={{
                width: '10rem',
                lineHeight: '2rem',
                background: '#eee',
                borderRadius: '1rem',
              }}
              onMouseDown={(e) =>
                controller
                  .getPlugIn(DragBasePlugin)
                  ?.drag(new EachWidget(0), e.nativeEvent)
              }
            >
              EachWidget
            </div>
          </div> */}
          <div className="editor">
            <div className="library">
              <div className="search">
                <div className="icon"></div>
                <input type="text" className="text" placeholder="模块搜索" />
              </div>
              {widgets.map((v) => (
                <div className="group" key={v.name}>
                  <div className="label">
                    <div className="text">{v.name}</div>
                  </div>
                  <div className="templates">
                    {v.widgets.map((w) => (
                      <div
                        className={`template ${selectedWidget?.id === w.id && 'active'}`}
                        key={`widget-${w.id}`}
                        onClick={() => {
                          if (
                            w.definition &&
                            Object.keys(w.definition).length
                          ) {
                            setSelectedWidget(w);
                          } else {
                            TrailOverlayController.show();
                          }
                        }}
                      >
                        <div className="icon">
                          <img src={w.icon} />
                        </div>
                        <div className="name">{w.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className="preview">
              <div
                className="editing-zone"
                ref={(r) => r && setRootContainer(r)}
              ></div>
              <div className="actions">
                <a href="#" className="button cancel">
                  取消
                </a>
                <a href="#" className="button submit" onClick={save}>
                  保存
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
