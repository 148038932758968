/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import React, { useEffect } from 'react';
import Overlay from '../Overlay/Overlay';
import EventEmitter from 'eventemitter3';
import './Data-Work-Overlay.scss';
import { WorkBackgroundDto } from '../../api/dto/work-background.dto';
import { Button, Table } from 'antd';
import DataWorkDetailOverlay, {
  DataWorkDetailOverlayController,
} from '../Data-Work-Detail-Overlay/Data-Work-Detail-Overlay';
import { WorkBackgroundAPI } from '../../api/work-background.api';

const DataWorkOverlayEE = new EventEmitter();

export const DataWorkOverlayController = {
  show: (workBackgrounds: WorkBackgroundDto[], onSave: () => void) =>
    DataWorkOverlayEE.emit('show', workBackgrounds, onSave),
};

export default function DataWorkOverlay() {
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [workBackgrounds, setWorkBackground] =
    React.useState<WorkBackgroundDto[]>();
  const onSave = React.useRef<() => void>(() => {
    return;
  });

  const handleShow = (
    workBackgrounds: WorkBackgroundDto[],
    onSaveCallback: () => void,
  ) => {
    setShowOverlay(true);
    setWorkBackground(workBackgrounds);
    onSave.current = onSaveCallback;
  };

  useEffect(() => {
    DataWorkOverlayEE.on('show', handleShow);
    return () => {
      DataWorkOverlayEE.off('show', handleShow);
    };
  }, []);

  return (
    <Overlay hidden={!showOverlay}>
      <DataWorkDetailOverlay />
      <div className="data-work-overlay">
        <div className="title">工作履历信息</div>
        <div className="content">
          <Table
            dataSource={workBackgrounds}
            rowKey="id"
            columns={[
              {
                title: '公司名称',
                dataIndex: 'employerName',
              },
              {
                title: '证明人姓名',
                dataIndex: 'colleagueName',
              },
              {
                title: '管理',
                dataIndex: 'id',
                width: 100,
                render: (v) => {
                  return (
                    <>
                      <Button
                        type="link"
                        onClick={() =>
                          WorkBackgroundAPI.getById(v).then((r) =>
                            DataWorkDetailOverlayController.show(
                              r,
                              onSave.current,
                            ),
                          )
                        }
                      >
                        编辑
                      </Button>
                    </>
                  );
                },
              },
            ]}
          />
        </div>
        <div className="actions">
          <a
            href="#"
            onClick={() => setShowOverlay(false)}
            className="button submit"
          >
            关闭
          </a>
        </div>
      </div>
    </Overlay>
  );
}
