/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { ControllerEvent } from '../controller/event';
import { ControllerEventType } from '../controller/event-type';
import { ContextMenuCapability } from '../plugins/context-menu/context-menu.capability';
import { WidgetCapability } from '../widget/capability';
import { WidgetPortable } from '../widget/portable';
import { WidgetSite } from '../widget/site';
import './flag-icon.widget.scss';

export type FlagIconWidgetConfig = {
  dataKey: string;
  override: number | null;
};
export class FlagIconWidget extends WidgetPortable<FlagIconWidgetConfig> {
  protected _capabilities: WidgetCapability[] = [
    new ContextMenuCapability([
      {
        key: 'edit-data',
        label: '编辑数据',
        onClick: (_e, widgetSite, _key) => {
          widgetSite.controller.emit(
            new ControllerEvent('DATASELECTSHOW'),
            (key: string) => {
              widgetSite.config.dataKey = key;
              widgetSite.refresh();
            },
          );
          widgetSite.controller.emit(
            new ControllerEvent(ControllerEventType.CHANGED),
            widgetSite,
          );
        },
      },
    ]),
  ];
  public readonly id = 'FlagIcon';
  constructor(layer: number, config: Partial<FlagIconWidgetConfig> = {}) {
    super(layer, Object.assign({ dataKey: '', override: null }, config));
  }

  protected _initContainer(): HTMLElement {
    const element = document.createElement('img');
    element.className = 'flag-icon-widget';
    return element;
  }

  public readonly STATUS_ICONS = [
    '/assets/widget-flag-icon-checked.png',
    '/assets/widget-flag-icon-forbidden.png',
    '/assets/widget-flag-icon-error.png',
  ];
  protected _initSite(widgetSite: WidgetSite<FlagIconWidgetConfig>): void {
    if (this.layer == widgetSite.controller.layer) {
      widgetSite.element.onclick = () => {
        // Toggle state
        widgetSite.config.override =
          (null === widgetSite.config.override
            ? 0
            : widgetSite.config.override + 1) % 3;
        widgetSite.refresh();
        widgetSite.controller.emit(
          new ControllerEvent(ControllerEventType.CHANGED),
          widgetSite,
        );
      };
    }
    return;
  }

  public renderSite(widgetSite: WidgetSite<FlagIconWidgetConfig>) {
    const verified = widgetSite.controller.getDataByKeyPath(
      widgetSite.config.dataKey,
    );
    if (this.layer > widgetSite.controller.layer) {
      (widgetSite.element as HTMLImageElement).src = this.STATUS_ICONS[1];
    } else {
      if (null === widgetSite.config.override) {
        (widgetSite.element as HTMLImageElement).src =
          this.STATUS_ICONS[verified ? 0 : 2];
      } else {
        (widgetSite.element as HTMLImageElement).src =
          this.STATUS_ICONS[widgetSite.config.override];
      }
    }
    return super.renderSite(widgetSite);
  }
}
