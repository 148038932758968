/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { ControllerBoundary } from '../controller/boundary';
import { ControllerEvent } from '../controller/event';
import { CommentCapability } from '../plugins/comment/comment.capability';
import { ContextMenuCapability } from '../plugins/context-menu/context-menu.capability';
import { DropCapability } from '../plugins/drag-drop/drop.capability';
import { WidgetCapability } from '../widget/capability';
import { WidgetPortable } from '../widget/portable';
import { WidgetSite } from '../widget/site';
import { ColumnLayoutWidget } from './column-layout.widget';
import './table-cell.widget.scss';

export type TableCellWidgetConfig = {
  bgColor: string;
  colSpan: number;
};
export class TableCellWidget extends WidgetPortable<TableCellWidgetConfig> {
  protected _capabilities: WidgetCapability[] = [
    new CommentCapability(),
    new ContextMenuCapability([
      {
        key: 'table-col-delete',
        label: '删除整列',
        onClick: (
          _controllerEvent: ControllerEvent,
          widgetSite: WidgetSite<any>,
        ) => {
          const colIndex = widgetSite.parent?.children.indexOf(widgetSite);
          if (undefined === colIndex || -1 === colIndex) {
            throw new Error('MAYBE BUG: Cannot find column index');
          }
          widgetSite.parent?.parent?.children.forEach((row) => {
            row.remove(row.children[colIndex]);
          });
        },
      },
      {
        key: 'table-col-add-prev',
        label: '在左侧添加一列',
        onClick: (
          _controllerEvent: ControllerEvent,
          widgetSite: WidgetSite<any>,
        ) => {
          const colIndex = widgetSite.parent?.children.indexOf(widgetSite);
          if (undefined === colIndex || -1 === colIndex) {
            throw new Error('MAYBE BUG: Cannot find column index');
          }
          widgetSite.parent?.parent?.children.forEach((row) => {
            row.before(new TableCellWidget(this.layer), row.children[colIndex]);
          });
        },
      },
      {
        key: 'table-col-add-next',
        label: '在右侧添加一列',
        onClick: (
          _controllerEvent: ControllerEvent,
          widgetSite: WidgetSite<any>,
        ) => {
          const colIndex = widgetSite.parent?.children.indexOf(widgetSite);
          if (undefined === colIndex || -1 === colIndex) {
            throw new Error('MAYBE BUG: Cannot find column index');
          }
          widgetSite.parent?.parent?.children.forEach((row) => {
            row.after(new TableCellWidget(this.layer), row.children[colIndex]);
          });
        },
      },
    ]),
  ];
  public readonly id = 'TableCell';
  constructor(
    layer: number,
    config: Partial<TableCellWidgetConfig> = {},
    children: WidgetPortable<any>[] = [],
  ) {
    super(
      layer,
      Object.assign({ bgColor: 'inherit', colSpan: 1 }, config),
      children,
    );
    this._capabilities.push(new DropCapability(this._onDrop));
  }

  protected _onDrop = (
    widgetPortable: WidgetPortable<unknown>,
    droppableWidgetSite: WidgetSite<any>,
    boundary: ControllerBoundary | null,
    boundaryWidgetSite: WidgetSite<any> | null,
  ) => {
    switch (boundary) {
      case ControllerBoundary.TOP:
      case ControllerBoundary.LEFT:
        if (!boundaryWidgetSite)
          throw new Error(
            'MAYBE BUG: boundaryWidgetSite is null when boundary was set',
          );
        droppableWidgetSite.before(widgetPortable, boundaryWidgetSite);
        break;
      case ControllerBoundary.BOTTOM:
      case ControllerBoundary.RIGHT:
        if (!boundaryWidgetSite)
          throw new Error(
            'MAYBE BUG: boundaryWidgetSite is null when boundary was set',
          );
        droppableWidgetSite.after(widgetPortable, boundaryWidgetSite);
        break;
      case ControllerBoundary.INSIDE:
      default:
        droppableWidgetSite.append(widgetPortable);
    }
  };

  protected _initContainer(): HTMLElement {
    const element = document.createElement('td');
    element.className = 'table-cell-widget';
    return element;
  }

  protected _initSite(site: WidgetSite<TableCellWidgetConfig>): void {
    site.element.style.backgroundColor = site.config.bgColor;
    if (0 === site.children.length) {
      site.append(new ColumnLayoutWidget(this.layer));
    }
  }

  public renderSite(widgetSite: WidgetSite<TableCellWidgetConfig>): void {
    widgetSite.element.style.backgroundColor = widgetSite.config.bgColor;
    (widgetSite.element as HTMLTableCellElement).colSpan =
      widgetSite.config.colSpan;
    return super.renderSite(widgetSite);
  }
}
