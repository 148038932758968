/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { ControllerBoundary } from '../controller/boundary';
import { DropCapability } from '../plugins/drag-drop/drop.capability';
import { WidgetCapability } from '../widget/capability';
import { WidgetPortable } from '../widget/portable';
import { WidgetSite } from '../widget/site';
import './row-layout.widget.scss';

export type RowLayoutWidgetConfig = null;
export class RowLayoutWidget extends WidgetPortable<RowLayoutWidgetConfig> {
  protected _capabilities: WidgetCapability[] = [];
  public readonly id: string = 'Row-Layout';
  constructor(layer: number) {
    super(layer, null);
    this._capabilities.push(new DropCapability(this._onDrop));
  }

  protected _onDrop = (
    widgetPortable: WidgetPortable<unknown>,
    droppableWidgetSite: WidgetSite<any>,
    boundary: ControllerBoundary | null,
    boundaryWidgetSite: WidgetSite<any> | null,
  ) => {
    switch (boundary) {
      case ControllerBoundary.TOP:
      case ControllerBoundary.LEFT:
        if (!boundaryWidgetSite)
          throw new Error(
            'MAYBE BUG: boundaryWidgetSite is null when boundary was set',
          );
        droppableWidgetSite.before(widgetPortable, boundaryWidgetSite);
        break;
      case ControllerBoundary.BOTTOM:
      case ControllerBoundary.RIGHT:
        if (!boundaryWidgetSite)
          throw new Error(
            'MAYBE BUG: boundaryWidgetSite is null when boundary was set',
          );
        droppableWidgetSite.after(widgetPortable, boundaryWidgetSite);
        break;
      case ControllerBoundary.INSIDE:
      default:
        droppableWidgetSite.append(widgetPortable);
    }
  };

  protected _initContainer(): HTMLElement {
    const containerElement = document.createElement('div');
    containerElement.className = 'row-layout-widget';
    return containerElement;
  }

  protected _initSite(_widgetSite: WidgetSite<null>): void {
    return;
  }

  public renderSite(widgetSite: WidgetSite<null>) {
    return super.renderSite(widgetSite);
  }
}
