/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import React from 'react';
import { Controller } from './lib/controller';
import { ColumnLayoutWidget } from './lib/widgets/column-layout.widget';
import { TextWidget } from './lib/widgets/text.widget';
import { DragIndicatorPlugin } from './lib/plugins/drag-drop/indicator.plugin';
import { DragBasePlugin } from './lib/plugins/drag-drop/base.plugin';
import { DragOverlayPlugin } from './lib/plugins/drag-drop/overlay.plugin';
import { ContextMenuBasePlugin } from './lib/plugins/context-menu/base.plugin';
import { TableWidget } from './lib/widgets/table.widget';
import { ContextMenuIndicatorPlugin } from './lib/plugins/context-menu/indicator.plugin';
import { ContextMenuDefaultImplementationPlugin } from './lib/plugins/context-menu/default-implementation.plugin';
// import { TableRowWidget } from './lib/widgets/table-row.widget';
// import { TableCellWidget } from './lib/widgets/table-cell.widget';
// import { DataWidget } from './lib/widgets/data.widget';

export default function UnifiedDesigner() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [controller, setController] = React.useState<Controller<null>>(
    new Controller(new ColumnLayoutWidget(0), 0),
  );

  const [rootContainer, setRootContainer] = React.useState<HTMLElement>();
  React.useEffect(() => {
    if (!rootContainer) return;
    controller.construct(rootContainer);
    return () => {
      controller.destruct();
    };
  }, [rootContainer]);

  React.useEffect(() => {
    // Plug-Ins
    DragBasePlugin.tryRegister(controller);
    DragOverlayPlugin.tryRegister(controller);
    DragIndicatorPlugin.tryRegister(controller);
    ContextMenuBasePlugin.tryRegister(controller);
    ContextMenuIndicatorPlugin.tryRegister(controller);
    ContextMenuDefaultImplementationPlugin.tryRegister(controller);
  }, [controller]);

  React.useEffect(() => {
    // Test here
    // controller.root.clear();
    // controller.root.append(
    //   new TableWidget(0, [
    //     new TableRowWidget(0, [
    //       new TableCellWidget(0, { bgColor: '#3373CA' }, [
    //         new TextWidget(0, {
    //           text: '学位验证',
    //           center: true,
    //           bold: true,
    //           color: '#fff',
    //         }),
    //       ]),
    //     ]),
    //   ]),
    // );
    // controller.root.append(
    //   new TableWidget(0, [
    //     new TableRowWidget(0, [
    //       new TableCellWidget(0, {}, [
    //         new TextWidget(0, {
    //           text: '调查内容',
    //           center: false,
    //           bold: true,
    //         }),
    //       ]),
    //       new TableCellWidget(0, {}, [
    //         new TextWidget(0, {
    //           text: '调查结果',
    //           center: false,
    //           bold: true,
    //         }),
    //       ]),
    //       new TableCellWidget(0, {}, [
    //         new TextWidget(0, {
    //           text: '真实性',
    //           center: false,
    //           bold: true,
    //         }),
    //       ]),
    //       new TableCellWidget(0, {}, [
    //         new TextWidget(0, {
    //           text: '说明',
    //           center: false,
    //           bold: true,
    //         }),
    //       ]),
    //     ]),
    //     new TableRowWidget(0, [
    //       new TableCellWidget(0, {}, [
    //         new TextWidget(0, {
    //           text: '学校名称',
    //           center: false,
    //           bold: false,
    //         }),
    //       ]),
    //       new TableCellWidget(0, {}, [
    //         new DataWidget(0, {
    //           dataKey: '',
    //           center: false,
    //           bold: false,
    //         }),
    //       ]),
    //       new TableCellWidget(0, {}),
    //       new TableCellWidget(0, {}, [
    //         new TextWidget(1, {
    //           center: false,
    //           bold: false,
    //         }),
    //       ]),
    //     ]),
    //     new TableRowWidget(0, [
    //       new TableCellWidget(0, {}, [
    //         new TextWidget(0, {
    //           text: '授予时间',
    //           center: false,
    //           bold: false,
    //         }),
    //       ]),
    //       new TableCellWidget(0, {}, [
    //         new DataWidget(0, {
    //           dataKey: '',
    //           center: false,
    //           bold: false,
    //         }),
    //       ]),
    //       new TableCellWidget(0, {}),
    //       new TableCellWidget(0, {}, [
    //         new TextWidget(1, {
    //           center: false,
    //           bold: false,
    //         }),
    //       ]),
    //     ]),
    //     new TableRowWidget(0, [
    //       new TableCellWidget(0, {}, [
    //         new TextWidget(0, {
    //           text: '学习专业',
    //           center: false,
    //           bold: false,
    //         }),
    //       ]),
    //       new TableCellWidget(0, {}, [
    //         new DataWidget(0, {
    //           dataKey: '',
    //           center: false,
    //           bold: false,
    //         }),
    //       ]),
    //       new TableCellWidget(0, {}),
    //       new TableCellWidget(0, {}, [
    //         new TextWidget(1, {
    //           center: false,
    //           bold: false,
    //         }),
    //       ]),
    //     ]),
    //     new TableRowWidget(0, [
    //       new TableCellWidget(0, {}, [
    //         new TextWidget(0, {
    //           text: '学位类型',
    //           center: false,
    //           bold: false,
    //         }),
    //       ]),
    //       new TableCellWidget(0, {}, [
    //         new DataWidget(0, {
    //           dataKey: '',
    //           center: false,
    //           bold: false,
    //         }),
    //       ]),
    //       new TableCellWidget(0, {}),
    //       new TableCellWidget(0, {}, [
    //         new TextWidget(1, {
    //           center: false,
    //           bold: false,
    //         }),
    //       ]),
    //     ]),
    //   ]),
    // );
    // const a = {
    //   portable: 'Column-Layout',
    //   layer: 0,
    //   config: null,
    //   children: [
    //     {
    //       portable: 'Table',
    //       layer: 0,
    //       config: null,
    //       children: [
    //         {
    //           portable: 'TableRow',
    //           layer: 0,
    //           config: null,
    //           children: [
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: '#3373CA' },
    //               children: [
    //                 {
    //                   portable: 'Text',
    //                   layer: 0,
    //                   config: {
    //                     bold: true,
    //                     center: true,
    //                     text: '学位验证',
    //                     color: '#fff',
    //                   },
    //                   children: [],
    //                 },
    //               ],
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       portable: 'Table',
    //       layer: 0,
    //       config: null,
    //       children: [
    //         {
    //           portable: 'TableRow',
    //           layer: 0,
    //           config: null,
    //           children: [
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: 'inherit' },
    //               children: [
    //                 {
    //                   portable: 'Text',
    //                   layer: 0,
    //                   config: {
    //                     bold: true,
    //                     center: false,
    //                     text: '调查内容',
    //                     color: 'inherit',
    //                   },
    //                   children: [],
    //                 },
    //               ],
    //             },
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: 'inherit' },
    //               children: [
    //                 {
    //                   portable: 'Text',
    //                   layer: 0,
    //                   config: {
    //                     bold: true,
    //                     center: false,
    //                     text: '调查结果',
    //                     color: 'inherit',
    //                   },
    //                   children: [],
    //                 },
    //               ],
    //             },
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: 'inherit' },
    //               children: [
    //                 {
    //                   portable: 'Text',
    //                   layer: 0,
    //                   config: {
    //                     bold: true,
    //                     center: false,
    //                     text: '真实性',
    //                     color: 'inherit',
    //                   },
    //                   children: [],
    //                 },
    //               ],
    //             },
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: 'inherit' },
    //               children: [
    //                 {
    //                   portable: 'Text',
    //                   layer: 0,
    //                   config: {
    //                     bold: true,
    //                     center: false,
    //                     text: '说明',
    //                     color: 'inherit',
    //                   },
    //                   children: [],
    //                 },
    //               ],
    //             },
    //           ],
    //         },
    //         {
    //           portable: 'TableRow',
    //           layer: 0,
    //           config: null,
    //           children: [
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: 'inherit' },
    //               children: [
    //                 {
    //                   portable: 'Text',
    //                   layer: 0,
    //                   config: {
    //                     bold: false,
    //                     center: false,
    //                     text: '学校名称',
    //                     color: 'inherit',
    //                   },
    //                   children: [],
    //                 },
    //               ],
    //             },
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: 'inherit' },
    //               children: [
    //                 {
    //                   portable: 'Data',
    //                   layer: 0,
    //                   config: { bold: false, center: false, dataKey: '' },
    //                   children: [],
    //                 },
    //               ],
    //             },
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: 'inherit' },
    //               children: [
    //                 {
    //                   portable: 'Column-Layout',
    //                   layer: 0,
    //                   config: null,
    //                   children: [],
    //                 },
    //               ],
    //             },
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: 'inherit' },
    //               children: [
    //                 {
    //                   portable: 'Text',
    //                   layer: 1,
    //                   config: {
    //                     bold: false,
    //                     center: false,
    //                     text: '',
    //                     color: 'inherit',
    //                   },
    //                   children: [],
    //                 },
    //               ],
    //             },
    //           ],
    //         },
    //         {
    //           portable: 'TableRow',
    //           layer: 0,
    //           config: null,
    //           children: [
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: 'inherit' },
    //               children: [
    //                 {
    //                   portable: 'Text',
    //                   layer: 0,
    //                   config: {
    //                     bold: false,
    //                     center: false,
    //                     text: '授予时间',
    //                     color: 'inherit',
    //                   },
    //                   children: [],
    //                 },
    //               ],
    //             },
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: 'inherit' },
    //               children: [
    //                 {
    //                   portable: 'Data',
    //                   layer: 0,
    //                   config: { bold: false, center: false, dataKey: '' },
    //                   children: [],
    //                 },
    //               ],
    //             },
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: 'inherit' },
    //               children: [
    //                 {
    //                   portable: 'Column-Layout',
    //                   layer: 0,
    //                   config: null,
    //                   children: [],
    //                 },
    //               ],
    //             },
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: 'inherit' },
    //               children: [
    //                 {
    //                   portable: 'Text',
    //                   layer: 1,
    //                   config: {
    //                     bold: false,
    //                     center: false,
    //                     text: '',
    //                     color: 'inherit',
    //                   },
    //                   children: [],
    //                 },
    //               ],
    //             },
    //           ],
    //         },
    //         {
    //           portable: 'TableRow',
    //           layer: 0,
    //           config: null,
    //           children: [
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: 'inherit' },
    //               children: [
    //                 {
    //                   portable: 'Text',
    //                   layer: 0,
    //                   config: {
    //                     bold: false,
    //                     center: false,
    //                     text: '学习专业',
    //                     color: 'inherit',
    //                   },
    //                   children: [],
    //                 },
    //               ],
    //             },
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: 'inherit' },
    //               children: [
    //                 {
    //                   portable: 'Data',
    //                   layer: 0,
    //                   config: { bold: false, center: false, dataKey: '' },
    //                   children: [],
    //                 },
    //               ],
    //             },
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: 'inherit' },
    //               children: [
    //                 {
    //                   portable: 'Column-Layout',
    //                   layer: 0,
    //                   config: null,
    //                   children: [],
    //                 },
    //               ],
    //             },
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: 'inherit' },
    //               children: [
    //                 {
    //                   portable: 'Text',
    //                   layer: 1,
    //                   config: {
    //                     bold: false,
    //                     center: false,
    //                     text: '',
    //                     color: 'inherit',
    //                   },
    //                   children: [],
    //                 },
    //               ],
    //             },
    //           ],
    //         },
    //         {
    //           portable: 'TableRow',
    //           layer: 0,
    //           config: null,
    //           children: [
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: 'inherit' },
    //               children: [
    //                 {
    //                   portable: 'Text',
    //                   layer: 0,
    //                   config: {
    //                     bold: false,
    //                     center: false,
    //                     text: '学位类型',
    //                     color: 'inherit',
    //                   },
    //                   children: [],
    //                 },
    //               ],
    //             },
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: 'inherit' },
    //               children: [
    //                 {
    //                   portable: 'Data',
    //                   layer: 0,
    //                   config: { bold: false, center: false, dataKey: '' },
    //                   children: [],
    //                 },
    //               ],
    //             },
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: 'inherit' },
    //               children: [
    //                 {
    //                   portable: 'Column-Layout',
    //                   layer: 0,
    //                   config: null,
    //                   children: [],
    //                 },
    //               ],
    //             },
    //             {
    //               portable: 'TableCell',
    //               layer: 0,
    //               config: { bgColor: 'inherit' },
    //               children: [
    //                 {
    //                   portable: 'Text',
    //                   layer: 1,
    //                   config: {
    //                     bold: false,
    //                     center: false,
    //                     text: '',
    //                     color: 'inherit',
    //                   },
    //                   children: [],
    //                 },
    //               ],
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //   ],
    // };
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        background: '#fff',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <div style={{ width: '20rem', userSelect: 'none' }}>
        <div
          className="widget"
          style={{
            width: '10rem',
            lineHeight: '2rem',
            background: '#eee',
            borderRadius: '1rem',
          }}
          onMouseDown={(e) =>
            controller
              .getPlugIn(DragBasePlugin)
              ?.drag(new TableWidget(0), e.nativeEvent)
          }
        >
          Table
        </div>
        <div
          className="widget"
          style={{
            width: '10rem',
            lineHeight: '2rem',
            background: '#eee',
            borderRadius: '1rem',
          }}
          onMouseDown={(e) =>
            controller
              .getPlugIn(DragBasePlugin)
              ?.drag(new TextWidget(0), e.nativeEvent)
          }
        >
          Text
        </div>
        <div
          className="widget"
          style={{
            width: '10rem',
            lineHeight: '2rem',
            background: '#eee',
            borderRadius: '1rem',
          }}
          onMouseDown={(e) =>
            controller
              .getPlugIn(DragBasePlugin)
              ?.drag(new TextWidget(1), e.nativeEvent)
          }
        >
          EditableText
        </div>
      </div>
      <div style={{ flex: 1 }} ref={(r) => r && setRootContainer(r)}></div>
    </div>
  );
}
