/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import React from 'react';
import './Overlay.scss';

export default function Overlay({
  hidden,
  children,
  className,
  onBackClick,
}: {
  hidden: boolean;
  children: React.ReactNode;
  className?: string;
  onBackClick?: () => void;
}) {
  return (
    <div
      className={`overlay ${className || ''}`}
      hidden={hidden}
      onClick={onBackClick}
    >
      <div onClick={(e) => e.stopPropagation()}>{children}</div>
    </div>
  );
}
