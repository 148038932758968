/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Controller } from '../../controller';
import { ControllerEvent } from '../../controller/event';
import { ControllerEventType } from '../../controller/event-type';
import { WidgetSite } from '../../widget/site';
import { ControllerPlugin } from '../plugin.interface';
import {
  ContextMenuCapability,
  // ContextMenuOption,
} from './context-menu.capability';
import './default-implementation.plugin.scss';

export class ContextMenuDefaultImplementationPlugin
  implements ControllerPlugin
{
  static tryRegister(controller: Controller<any>) {
    if (
      !controller.isPlugInRegistered(ContextMenuDefaultImplementationPlugin)
    ) {
      controller.registerPlugin(ContextMenuDefaultImplementationPlugin);
    }
  }

  constructor(private readonly __controller: Controller<any>) {}

  private __lastElement: HTMLElement | null = null;
  private _onContextMenuShow = (
    controllerEvent: ControllerEvent,
    initialMouseEvent: MouseEvent,
    widgetSite: WidgetSite<any>,
  ) => {
    const menuWidgets = widgetSite
      .ancestors()
      .filter(
        (v) =>
          (v.portable.capabilityGet(ContextMenuCapability)?.options || [])
            .length,
      );
    const menuOptions = menuWidgets.map(
      (v) => v.portable.capabilityGet(ContextMenuCapability)?.options || [],
    );

    const menuElement = document.createElement('div');
    menuElement.className = 'context-menu-default-implementation-plugin';
    menuElement.style.left = initialMouseEvent.clientX + 'px';
    menuElement.style.top = initialMouseEvent.clientY + 'px';
    menuElement.style.zIndex = '9';
    for (let i = 0; i < menuOptions.length; i++) {
      const menuOption = menuOptions[i];
      const menuWidget = menuWidgets[i];
      const groupElement = document.createElement('div');
      groupElement.className = 'group';
      menuOption.forEach((v) => {
        const itemElement = document.createElement('a');
        itemElement.href = '#';
        itemElement.className = 'item';
        itemElement.innerHTML = v.label;
        itemElement.onclick = (e: MouseEvent) => {
          this.__controller.emit(
            new ControllerEvent(ControllerEventType.CONTEXTMENUCLICK),
            e,
            menuWidget,
            v.key,
          );
          v.onClick(controllerEvent, menuWidget, v.key);
        };
        groupElement.appendChild(itemElement);
      });
      menuElement.appendChild(groupElement);
      if (i !== menuOptions.length - 1) {
        const separatorElement = document.createElement('div');
        separatorElement.className = 'separator';
        menuElement.appendChild(separatorElement);
      }
    }
    this.__lastElement = menuElement;
    this.__controller.overlayAdd(menuElement);
  };

  private _onContextMenuHide = (
    _controllerEvent: ControllerEvent,
    _initialMouseEvent: MouseEvent,
  ) => {
    if (this.__lastElement) {
      this.__controller.overlayRemove(this.__lastElement);
      this.__lastElement = null;
    }
  };

  public construct(): void {
    this.__controller.on(
      ControllerEventType.CONTEXTMENUSHOW,
      this._onContextMenuShow,
    );
    this.__controller.on(
      ControllerEventType.CONTEXTMENUHIDE,
      this._onContextMenuHide,
    );
    this.__controller.on(
      ControllerEventType.CONTEXTMENUCLICK,
      this._onContextMenuHide,
    );
  }

  public destruct(): void {
    this.__controller.off(
      ControllerEventType.CONTEXTMENUSHOW,
      this._onContextMenuShow,
    );
    this.__controller.off(
      ControllerEventType.CONTEXTMENUHIDE,
      this._onContextMenuHide,
    );
    this.__controller.off(
      ControllerEventType.CONTEXTMENUCLICK,
      this._onContextMenuHide,
    );
  }
}
