/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import React, { useEffect } from 'react';
import Overlay from '../Overlay/Overlay';
import EventEmitter from 'eventemitter3';
import './Data-Identity-Overlay.scss';
import { IdentityBackgroundDto } from '../../api/dto/identity-background.dto';
import { DatePicker, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { IdentityBackgroundAPI } from '../../api/identity-background.api';

const DataIdentityOverlayEE = new EventEmitter();

export const DataIdentityOverlayController = {
  show: (
    candidateId: string,
    identityBackground: IdentityBackgroundDto,
    onSave: () => void,
  ) =>
    DataIdentityOverlayEE.emit('show', candidateId, identityBackground, onSave),
};

export default function DataIdentityOverlay() {
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [candidateId, setCandidateId] = React.useState<string>();
  const [identityBackground, setIdentityBackground] =
    React.useState<IdentityBackgroundDto>();
  const onSave = React.useRef<() => void>(() => {
    return;
  });

  const handleShow = (
    candidateId: string,
    identityBackground: IdentityBackgroundDto,
    onSaveCallback: () => void,
  ) => {
    setShowOverlay(true);
    setCandidateId(candidateId);
    setIdentityBackground(identityBackground);
    onSave.current = onSaveCallback;
  };

  useEffect(() => {
    DataIdentityOverlayEE.on('show', handleShow);
    return () => {
      DataIdentityOverlayEE.off('show', handleShow);
    };
  }, []);

  const submit = async () => {
    if (!candidateId || !identityBackground) return;

    await IdentityBackgroundAPI.updateById(candidateId, identityBackground);
    setShowOverlay(false);
    onSave.current();
  };
  return (
    <Overlay hidden={!showOverlay}>
      <div className="data-identity-overlay">
        <div className="title">身份认证信息</div>
        <div className="content">
          <div className="field">
            <div className="label">姓名</div>
            <Input
              onInput={(e) =>
                setIdentityBackground(
                  Object.assign({}, identityBackground, {
                    name: e.currentTarget.value,
                  }),
                )
              }
              value={identityBackground?.name}
            />
          </div>
          <div className="field">
            <div className="label">身份证</div>
            <Input
              onInput={(e) =>
                setIdentityBackground(
                  Object.assign({}, identityBackground, {
                    identityNumber: e.currentTarget.value,
                  }),
                )
              }
              value={identityBackground?.identityNumber}
            />
          </div>
          <div className="field">
            <div className="label">年龄</div>
            <Input
              onInput={(e) =>
                setIdentityBackground(
                  Object.assign({}, identityBackground, {
                    age: +e.currentTarget.value,
                  }),
                )
              }
              type="number"
              value={identityBackground?.age}
            />
          </div>
          <div className="field">
            <div className="label">性别</div>
            <Select
              onChange={(e) => {
                setIdentityBackground(
                  Object.assign({}, identityBackground, {
                    sex: e,
                  }),
                );
              }}
              value={identityBackground?.sex}
            >
              <Select.Option value="MALE">男</Select.Option>
              <Select.Option value="FEMALE">女</Select.Option>
            </Select>
          </div>
          <div className="field">
            <div className="label">出生日期</div>
            <DatePicker
              allowClear={false}
              onChange={(date) => {
                setIdentityBackground(
                  Object.assign({}, identityBackground, {
                    dateOfBirth: date.toISOString(),
                  }),
                );
              }}
              value={dayjs(identityBackground?.dateOfBirth)}
            />
          </div>
          <div className="field">
            <div className="label">籍贯</div>
            <Input
              onInput={(e) => {
                setIdentityBackground(
                  Object.assign({}, identityBackground, {
                    birthPlace: e.currentTarget.value,
                  }),
                );
              }}
              value={identityBackground?.birthPlace}
            />
          </div>
        </div>
        <div className="actions">
          <a
            href="#"
            onClick={() => setShowOverlay(false)}
            className="button cancel"
          >
            取消
          </a>
          <a href="#" onClick={submit} className="button submit">
            确认
          </a>
        </div>
      </div>
    </Overlay>
  );
}
