/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { ChatDto } from './dto/chat.dto';
import { HttpAPI } from './http.api';
import Cookies from 'js-cookie';

export class ChatAPI {
  static chat(message: string) {
    return HttpAPI.postStream(Cookies.get('access-token') || '', '/chat', {
      message,
    });
  }

  static getHistory(): Promise<ChatDto[]> {
    return HttpAPI.get(Cookies.get('access-token') || '', '/chat');
  }
}
