/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Controller } from '../controller';
import { ControllerEvent } from '../controller/event';
import { ContextMenuCapability } from '../plugins/context-menu/context-menu.capability';
import { WidgetCapability } from '../widget/capability';
import { WidgetDump } from '../widget/dump';
import { WidgetPortable } from '../widget/portable';
import { WidgetSite } from '../widget/site';
import './dump-wrapper.widget.scss';

export type DumpWrapperWidgetConfig = {
  dump?: WidgetDump;
};
export class DumpWrapperWidget extends WidgetPortable<DumpWrapperWidgetConfig> {
  protected _capabilities: WidgetCapability[] = [
    new ContextMenuCapability([
      {
        key: 'delete',
        label: '删除整个元素',
        onClick: function (
          _e: ControllerEvent,
          widgetSite: WidgetSite<any>,
        ): void {
          widgetSite.parent?.remove(widgetSite);
        },
      },
    ]),
  ];
  public readonly id = 'DumpWrapper';
  constructor(layer: number, config: Partial<DumpWrapperWidgetConfig> = {}) {
    super(layer, Object.assign({ dump: undefined }, config));
  }

  protected _initContainer(): HTMLElement {
    const element = document.createElement('div');
    element.className = 'dump-wrapper-widget';
    return element;
  }

  protected _initSite(
    widgetSite: WidgetSite<DumpWrapperWidgetConfig>,
    controller: Controller<any>,
  ): void {
    if (!widgetSite.config.dump) return;
    const dumpWidget = controller.rebuild(
      widgetSite.config.dump,
      widgetSite,
      true,
    );
    widgetSite.config.dump = undefined;
    widgetSite.children.push(dumpWidget);
    return;
  }

  public renderSite(widgetSite: WidgetSite<DumpWrapperWidgetConfig>) {
    return super.renderSite(widgetSite);
  }
}
