/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import Cookies from 'js-cookie';
import { HttpAPI } from './http.api';
import { ReportStructureDto } from './dto/report-structure.dto';
import { ReportDto } from './dto/report.dto';

export class ReportAPI {
  static getStructure(): Promise<ReportStructureDto> {
    return HttpAPI.get(Cookies.get('access-token') || '', `report/structure`);
  }

  static getById(id: string): Promise<ReportDto> {
    return HttpAPI.get(Cookies.get('access-token') || '', `report/by-id/${id}`);
  }

  static updateById(id: string, data: any) {
    return HttpAPI.patch(
      Cookies.get('access-token') || '',
      `report/by-id/${id}`,
      data,
    );
  }

  static list(): Promise<ReportDto[]> {
    return HttpAPI.get(Cookies.get('access-token') || '', `report`);
  }

  static requestReviewById(id: string) {
    return HttpAPI.post(
      Cookies.get('access-token') || '',
      `report/by-id/${id}/review`,
    );
  }

  static acceptReviewById(id: string) {
    return HttpAPI.patch(
      Cookies.get('access-token') || '',
      `report/by-id/${id}/review/accept`,
    );
  }
}
