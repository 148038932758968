/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import React, { useEffect } from 'react';
import Overlay from '../Overlay/Overlay';
import EventEmitter from 'eventemitter3';
import './Data-Education-Overlay.scss';
import { DatePicker, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { EducationBackgroundDto } from '../../api/dto/education-background.dto';
import { EducationBackgroundAPI } from '../../api/education-background.api';

const DataEducationOverlayEE = new EventEmitter();

export const DataEducationOverlayController = {
  show: (educationBackground: EducationBackgroundDto, onSave: () => void) =>
    DataEducationOverlayEE.emit('show', educationBackground, onSave),
};

export default function DataEducationOverlay() {
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [educationBackground, setEducationBackground] =
    React.useState<EducationBackgroundDto>();
  const onSave = React.useRef<() => void>(() => {
    return;
  });

  const handleShow = (
    educationBackground: EducationBackgroundDto,
    onSaveCallback: () => void,
  ) => {
    setShowOverlay(true);
    setEducationBackground(educationBackground);
    onSave.current = onSaveCallback;
  };

  useEffect(() => {
    DataEducationOverlayEE.on('show', handleShow);
    return () => {
      DataEducationOverlayEE.off('show', handleShow);
    };
  }, []);

  const submit = async () => {
    if (!educationBackground) return;

    await EducationBackgroundAPI.updateById(
      educationBackground.id,
      educationBackground,
    );
    setShowOverlay(false);
    onSave.current();
  };
  return (
    <Overlay hidden={!showOverlay}>
      <div className="data-education-overlay">
        <div className="title">学历认证信息</div>
        <div className="content">
          <div className="field">
            <div className="label">名称</div>
            <Input
              onChange={(e) => {
                setEducationBackground(
                  Object.assign({}, educationBackground, {
                    institutionName: e.currentTarget.value,
                  }),
                );
              }}
              value={educationBackground?.institutionName}
            />
          </div>
          <div className="field">
            <div className="label">双一流</div>
            <Select
              onChange={(e) => {
                setEducationBackground(
                  Object.assign({}, educationBackground, {
                    doubleFirstClass: e,
                  }),
                );
              }}
              value={educationBackground?.doubleFirstClass}
            >
              <Select.Option value={true}>是</Select.Option>
              <Select.Option value={false}>否</Select.Option>
            </Select>
          </div>
          <div className="field">
            <div className="label">985</div>
            <Select
              onChange={(e) => {
                setEducationBackground(
                  Object.assign({}, educationBackground, {
                    project985: e,
                  }),
                );
              }}
              value={educationBackground?.project985}
            >
              <Select.Option value={true}>是</Select.Option>
              <Select.Option value={false}>否</Select.Option>
            </Select>
          </div>
          <div className="field">
            <div className="label">211</div>
            <Select
              onChange={(e) => {
                setEducationBackground(
                  Object.assign({}, educationBackground, {
                    project211: e,
                  }),
                );
              }}
              value={educationBackground?.project211}
            >
              <Select.Option value={true}>是</Select.Option>
              <Select.Option value={false}>否</Select.Option>
            </Select>
          </div>
          <div className="field">
            <div className="label">学习专业</div>
            <Input
              onChange={(e) => {
                setEducationBackground(
                  Object.assign({}, educationBackground, {
                    programName: e.currentTarget.value,
                  }),
                );
              }}
              value={educationBackground?.programName}
            />
          </div>
          <div className="field">
            <div className="label">学习层次</div>
            <Input
              onChange={(e) => {
                setEducationBackground(
                  Object.assign({}, educationBackground, {
                    degreeLevel: e.currentTarget.value,
                  }),
                );
              }}
              value={educationBackground?.degreeLevel}
            />
          </div>
          <div className="field">
            <div className="label">学习形式</div>
            <Input
              onChange={(e) => {
                setEducationBackground(
                  Object.assign({}, educationBackground, {
                    modeOfStudy: e.currentTarget.value,
                  }),
                );
              }}
              value={educationBackground?.modeOfStudy}
            />
          </div>
          <div className="field">
            <div className="label">学位类型</div>
            <Input
              onChange={(e) => {
                setEducationBackground(
                  Object.assign({}, educationBackground, {
                    degree: e.currentTarget.value,
                  }),
                );
              }}
              value={educationBackground?.degree}
            />
          </div>
          <div className="field">
            <div className="label">毕业时间</div>
            <DatePicker
              allowClear={false}
              onChange={(date) => {
                setEducationBackground(
                  Object.assign({}, educationBackground, {
                    degreeGrantedDate: date.toISOString(),
                  }),
                );
              }}
              value={dayjs(educationBackground?.degreeGrantedDate)}
            />
          </div>
          <div className="field">
            <div className="label">开始时间</div>
            <DatePicker
              allowClear={false}
              onChange={(date) => {
                setEducationBackground(
                  Object.assign({}, educationBackground, {
                    start: date.toISOString(),
                  }),
                );
              }}
              value={dayjs(educationBackground?.start)}
            />
          </div>
          <div className="field">
            <div className="label">结束时间</div>
            <DatePicker
              allowClear={false}
              onChange={(date) => {
                setEducationBackground(
                  Object.assign({}, educationBackground, {
                    end: date.toISOString(),
                  }),
                );
              }}
              value={dayjs(educationBackground?.end)}
            />
          </div>
        </div>
        <div className="actions">
          <a
            href="#"
            onClick={() => setShowOverlay(false)}
            className="button cancel"
          >
            取消
          </a>
          <a href="#" onClick={submit} className="button submit">
            确认
          </a>
        </div>
      </div>
    </Overlay>
  );
}
