/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { AuthAPI } from '../../api/auth.api';
import SidebarSchedule from '../Sidebar-Schedule/Sidebar-Schedule';
import './Data-Sidebar.scss';

export default function DataSidebar() {
  const user = AuthAPI.getLocalUser();
  return (
    <div className="sidebar-component">
      <div className="user">
        <div className="avatar"></div>
        <div className="info">
          <div className="name">{user?.name}</div>
          <div className="role">{user?.title}</div>
        </div>
        <div className="dropdown"></div>
      </div>
      <div className="nav"></div>
      <SidebarSchedule />
    </div>
  );
}
