/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import React, { useEffect, useRef } from 'react';
import Overlay from '../Overlay/Overlay';
import EventEmitter from 'eventemitter3';
import './Document-Builder-Review-Overlay.scss';
import { ReportAPI } from '../../api/report.api';

const DocumentBuilderReviewOverlayEE = new EventEmitter();

export const DocumentBuilderReviewOverlayController = {
  show: (reportId: string, onSubmit: () => void) =>
    DocumentBuilderReviewOverlayEE.emit('show', reportId, onSubmit),
};

export default function DocumentBuilderReviewOverlay() {
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [reportId, setReportId] = React.useState<string>('');

  const onSubmit = useRef<() => void>(() => {
    return;
  });
  const handleShow = (reportId: string, onSubmitCallback: () => void) => {
    setShowOverlay(true);
    setReportId(reportId);
    onSubmit.current = onSubmitCallback;
  };

  useEffect(() => {
    DocumentBuilderReviewOverlayEE.on('show', handleShow);
    return () => {
      DocumentBuilderReviewOverlayEE.off('show', handleShow);
    };
  }, []);

  const submit = async () => {
    setShowOverlay(false);
    await ReportAPI.requestReviewById(reportId);
    onSubmit.current();
  };

  return (
    <Overlay hidden={!showOverlay}>
      <div className="document-builder-review-overlay">
        <div className="title">提价审核</div>
        <div className="image"></div>
        <div className="confirmation">
          报告完成度<span className="progress">33%</span>
          <br />
          您确认提交审核至审核员吗？
        </div>
        <div className="summary">
          <div className="label">
            <div className="icon"></div>
            <div className="text">报告进程</div>
          </div>
          <div className="progress">
            <div className="indicator"></div>
          </div>
        </div>
        <div className="actions">
          <a
            href="#"
            onClick={() => setShowOverlay(false)}
            className="button cancel"
          >
            取消
          </a>
          <a href="#" onClick={submit} className="button submit">
            确认提交
          </a>
        </div>
      </div>
    </Overlay>
  );
}
