/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

export class UtilityDOM {
  static boggart(element: HTMLElement): HTMLElement {
    const cloneElement = (e: HTMLElement) => {
      const newElement = document.createElement(e.tagName);
      const styles = getComputedStyle(e);
      newElement.className = e.className;
      for (const key in styles) {
        if ('string' !== typeof styles[key]) continue;
        if (!(key in newElement.style) || 'cssText' === key) continue;
        newElement.style[key] = styles[key];
      }
      newElement.style.pointerEvents = 'none';
      for (let i = 0; i < e.children.length; i++) {
        const child = e.children[i];
        if (child instanceof HTMLElement) {
          newElement.appendChild(cloneElement(child));
        } else {
          newElement.innerHTML += child.outerHTML;
        }
      }

      if (0 === e.children.length) newElement.innerHTML += e.innerText;

      return newElement;
    };
    return cloneElement(element);

    // const reactElement = React.createElement(element.tagName.toLowerCase(), {
    //   className: element.className,
    //   ref: (r: HTMLElement) => {
    //     if (!r) return;
    //     r.innerHTML = cloneElement(element).innerHTML;
    //     r.style.width = `${element.offsetWidth}px`;
    //     r.style.height = `${element.offsetHeight}px`;
    //     r.style.background = '#fff';
    //     r.style.boxShadow = '0 0 10px #33333333';
    //     if (style) Object.assign(r.style, style);
    //   },
    // });
    // return reactElement;
  }

  static parentsUntil(
    element: Node,
    until: Node = document.body,
  ): Node[] | null {
    let search: Node | null = element;
    const foundParents: Node[] = [];
    while (search) {
      foundParents.unshift(search);
      if (search === until) break;
      search = search.parentNode;
    }
    if (foundParents[0] !== until) return null;
    return foundParents;
  }
}
