/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { ReportDto } from '../../../api/dto/report.dto';
import { ControllerBoundary } from './controller/boundary';
import { ControllerEvent } from './controller/event';
import {
  ControllerEventType,
  ControllerEventTypeCallbackType,
} from './controller/event-type';
import { ControllerLayer } from './controller/layer';
import { ControllerPlugin } from './plugins/plugin.interface';
import { WidgetDump } from './widget/dump';
import { WidgetPortable } from './widget/portable';
import { WidgetSite } from './widget/site';
import { ColumnLayoutWidget } from './widgets/column-layout.widget';
import { DataWidget } from './widgets/data.widget';
import { DumpWrapperWidget } from './widgets/dump-wrapper.widget';
import { EachWidget } from './widgets/each.widget';
import { FlagIconWidget } from './widgets/flag-icon.widget';
import { HeaderWidget } from './widgets/header.widget';
import { RowLayoutWidget } from './widgets/row-layout.widget';
import { TableCellWidget } from './widgets/table-cell.widget';
import { TableRowWidget } from './widgets/table-row.widget';
import { TableWidget } from './widgets/table.widget';
import { TextWidget } from './widgets/text.widget';

export class Controller<T_ROOT extends object | null | unknown> {
  private _root: WidgetSite<T_ROOT>;
  constructor(
    rootWidget: WidgetPortable<T_ROOT>,
    public readonly layer: number = ControllerLayer.VIEW_ONLY,
  ) {
    this._root = rootWidget.site(null, this);

    this._overlayElement = this._constructOverlay();
    this._containerElement = this._constructContainer();
  }

  //#region Data

  private _data: any;
  public setData(data: ReportDto) {
    this._data = data;
    this.root.refresh();
  }

  public get data(): any {
    return this._data;
  }

  public getDataByKeyPath(dataKeyPath: string): any {
    if (!dataKeyPath) return undefined;
    const dataKeys = dataKeyPath.split('.');
    let search =
      '$' === dataKeys[0][0]
        ? (this.__temporaryData[dataKeys.shift()?.substring(1) || ''] ||
            [])[0] || {}
        : this.data;
    for (const dataKey of dataKeys) {
      if (!search) {
        return undefined;
      }
      search = search[dataKey];
    }
    return search;
  }

  private __temporaryData: Record<string, any[]> = {};
  public useTemporaryData<T>(key: string, item: T, scopeRun: () => void) {
    this.__temporaryData[key] = this.__temporaryData[key] || [];
    this.__temporaryData[key].unshift(item);
    scopeRun();
    this.__temporaryData[key].shift();
  }

  //#endregion

  //#region Dump & restore

  public dump() {
    return this._root;
  }

  public restore(dump: WidgetDump) {
    this._root = this.rebuild(dump);
    this._containerElement.innerHTML = '';
    this._containerElement.appendChild(this._root.element);
  }

  public rebuild(
    dump: WidgetDump,
    parent: WidgetSite<any> | null = null,
    randomizeId = false,
  ) {
    let portableWidget: WidgetPortable<any>;
    switch (dump.portable) {
      case 'Column-Layout':
        portableWidget = new ColumnLayoutWidget(dump.layer);
        break;
      case 'Row-Layout':
        portableWidget = new RowLayoutWidget(dump.layer);
        break;
      case 'Text':
        portableWidget = new TextWidget(dump.layer);
        break;
      case 'FlagIcon':
        portableWidget = new FlagIconWidget(dump.layer);
        break;
      case 'Data':
        portableWidget = new DataWidget(dump.layer);
        break;
      case 'Table':
        portableWidget = new TableWidget(dump.layer);
        break;
      case 'TableRow':
        portableWidget = new TableRowWidget(dump.layer);
        break;
      case 'TableCell':
        portableWidget = new TableCellWidget(dump.layer);
        break;
      case 'Header':
        portableWidget = new HeaderWidget(dump.layer);
        break;
      case 'DumpWrapper':
        portableWidget = new DumpWrapperWidget(dump.layer);
        break;
      case 'Each':
        portableWidget = new EachWidget(dump.layer);
        break;
      default:
        throw new Error('Unknown portable widget: ' + dump.portable);
    }
    const widgetSite = portableWidget.site(
      parent,
      this,
      randomizeId ? null : dump.id,
    );
    if (dump.config) {
      Object.assign(widgetSite.config, dump.config);
    }
    widgetSite.clear();
    for (const child of dump.children) {
      widgetSite.children.push(this.rebuild(child, widgetSite, randomizeId));
    }
    widgetSite.refresh();
    return widgetSite;
  }
  //#endregion

  //#region Event

  private _callbacks: Record<
    string,
    ((...a: [ControllerEvent, ...any]) => void)[]
  > = {};

  public on<T extends ControllerEventType | string>(
    event: T,
    cb: ControllerEventTypeCallbackType<T>,
  ) {
    this._callbacks[event] = this._callbacks[event] || [];
    this._callbacks[event].push(cb);
  }

  public off<T extends ControllerEventType | string>(
    event: T,
    cb: ControllerEventTypeCallbackType<T>,
  ) {
    const index = this._callbacks[event].indexOf(cb);
    if (-1 !== index) {
      this._callbacks[event].splice(index, 1);
    }
  }

  public emit(e: ControllerEvent, ...a: any[]) {
    this._callbacks[e.type] = this._callbacks[e.type] || [];
    for (const cb of this._callbacks[e.type]) {
      cb(e, ...a);
    }
  }

  //#endregion

  //#region Global event handlers

  private __scrollEventHandler = () => {
    this.emit(new ControllerEvent(ControllerEventType.SCROLL));
  };

  private __resizeEventHandler = () => {
    this.emit(new ControllerEvent(ControllerEventType.RESIZE));
  };

  //#endregion

  //#region Constructors & Destructors

  private readonly _overlayElement: HTMLElement;
  private _constructOverlay() {
    const overlayElement = document.createElement('div');
    overlayElement.style.position = 'absolute';
    overlayElement.style.top = '0';
    overlayElement.style.left = '0';
    overlayElement.style.width = '100%';
    overlayElement.style.height = '100%';
    overlayElement.style.pointerEvents = 'none';
    return overlayElement;
  }

  private readonly _containerElement: HTMLElement;
  private _constructContainer() {
    const containerElement = document.createElement('div');
    containerElement.style.width = '100%';
    containerElement.style.height = '100%';
    containerElement.appendChild(this._root.element);
    return containerElement;
  }

  public rootContainer: HTMLElement | null = null;
  public construct(rootContainer: HTMLElement) {
    rootContainer.appendChild(this._overlayElement);
    rootContainer.appendChild(this._containerElement);
    Object.values(this._plugIns).forEach((v) => v.construct(rootContainer));
    this.rootContainer = rootContainer;
    this.rootContainer.addEventListener('scroll', this.__scrollEventHandler);
    window.addEventListener('resize', this.__resizeEventHandler);
  }

  public destruct() {
    this._overlayElement.remove();
    this._containerElement.remove();
    Object.values(this._plugIns).forEach((v) => v.destruct());

    this.rootContainer?.removeEventListener(
      'scroll',
      this.__scrollEventHandler,
    );
    this.rootContainer = null;
    window.removeEventListener('resize', this.__resizeEventHandler);
  }

  //#endregion

  //#region Widget operations

  public findWidgetByElement(element: HTMLElement, filterLayer = this.layer) {
    return this._root.findWidgetByElement(element, filterLayer);
  }

  public findInteractivableWidgetByElement(element: HTMLElement) {
    const ancestors =
      this._root.findWidgetByElement(element, -1)?.ancestors() || [];
    return ancestors.find((v) => v.portable.layer >= this.layer);
  }

  public findWidgetById(id: string) {
    return this._root.findWidgetById(id);
  }

  public get root(): WidgetSite<T_ROOT> {
    return this._root;
  }

  //#endregion

  //#region Overlay layer

  public overlayAdd(element: HTMLElement) {
    element.style.position = 'absolute';
    this._overlayElement.appendChild(element);
  }

  public overlayRemove(element: HTMLElement) {
    this._overlayElement.removeChild(element);
  }

  //#endregion

  //#region Indicator

  private _marks: Record<string, HTMLElement> = {};

  public mark(
    widget: WidgetSite<any>,
    boundary: ControllerBoundary,
    mark: HTMLElement,
  ) {
    const rect = widget.element.getBoundingClientRect();
    mark.style.position = 'absolute';
    switch (boundary) {
      case ControllerBoundary.TOP:
        mark.style.top = `${rect.top - 2}px`;
        mark.style.left = `${rect.left}px`;
        mark.style.width = `${rect.width}px`;
        mark.style.height = `4px`;
        break;
      case ControllerBoundary.BOTTOM:
        mark.style.top = `${rect.top + rect.height - 2}px`;
        mark.style.left = `${rect.left}px`;
        mark.style.width = `${rect.width}px`;
        mark.style.height = `4px`;
        break;
      case ControllerBoundary.INSIDE:
        mark.style.top = `${rect.top}px`;
        mark.style.left = `${rect.left}px`;
        mark.style.width = `${rect.width}px`;
        mark.style.height = `${rect.height}px`;
        break;
    }
    this._overlayElement.appendChild(mark);
    this._marks[widget.id] = mark;
  }

  public unmark(widget: WidgetSite<any>) {
    const mark = this._marks[widget.id];
    if (!mark) return;
    this._overlayElement.removeChild(mark);
    delete this._marks[widget.id];
  }

  //#endregion

  //#region Plug-Ins

  private _plugIns: Record<string, ControllerPlugin> = {};
  public registerPlugin<T extends any[]>(
    plugIn: {
      new (c: Controller<any>, ...args: T): ControllerPlugin;
    },
    ...a: T
  ) {
    if (plugIn.name in this._plugIns) {
      throw new Error('Plug-in already exists');
    }
    this._plugIns[plugIn.name] = new plugIn(this, ...a);
  }

  public unregisterPlugin(plugIn: {
    new (c: Controller<any>): ControllerPlugin;
  }) {
    if (!(plugIn.name in this._plugIns)) {
      throw new Error('Plug-in does not exist');
    }
    delete this._plugIns[plugIn.name];
  }

  public getPlugIn<
    T_PLUGIN_CLASS extends {
      new (c: Controller<any>): ControllerPlugin;
    },
  >(plugIn: T_PLUGIN_CLASS): InstanceType<T_PLUGIN_CLASS> | null {
    return this._plugIns[plugIn.name] as InstanceType<T_PLUGIN_CLASS>;
  }

  public isPlugInRegistered(plugIn: {
    new (c: Controller<any>, ...args: any[]): ControllerPlugin;
  }) {
    return plugIn.name in this._plugIns;
  }

  //#endregion
}
