/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import React from 'react';
import './Page.scss';
import { Input, Switch } from 'antd';
import { AuthAPI } from '../../api/auth.api';

export default function SignInPage() {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [shouldRemember, setShouldRemember] = React.useState(false);

  const performSignIn = async () => {
    try {
      await AuthAPI.signIn(username, password, shouldRemember);
      location.href = '/orders';
    } catch {}
  };

  return (
    <div className="sign-in-page">
      <div className="logo"></div>
      <div className="layout">
        <div className="banner">
          <div className="slogan">
            <div className="heading">全面型、易用性、高质量</div>
            <div className="text">
              以智能化为核心，实现背景调查生产业务的全生命周期管理
            </div>
          </div>
          <div className="image"></div>
        </div>
        <div className="form">
          <div className="title">登录您的账户</div>
          <div className="greeting">欢迎回来！我们很高兴再次见到您</div>
          <div className="fields">
            <div className="field">
              <div className="label">你的账户</div>
              <input
                type="text"
                className="text"
                placeholder="输入账户"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="field">
              <div className="label">你的密码</div>
              <Input.Password
                className="text"
                placeholder="输入密码"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="extra">
            <div className="remember">
              <Switch checked={shouldRemember} onChange={setShouldRemember} />
              <div className="label">记住密码</div>
            </div>
            <a href="#" className="forgot">
              忘记密码?
            </a>
          </div>
          <a href="#" className="button" onClick={performSignIn}>
            登录
          </a>
          <div className="hint">还没有账户? 请联系管理员</div>
        </div>
      </div>
    </div>
  );
}
