/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import dayjs from 'dayjs';
import { ReportCommentDto } from '../../api/dto/report-comment.dto';
import './Document-Builder-Comment-Overlay.scss';
import React from 'react';
import EventEmitter from 'eventemitter3';
import { ReportCommentAPI } from '../../api/report-comment.api';

const DocumentBuilderCommentOverlayEE = new EventEmitter();

export const DocumentBuilderCommentOverlayController = {
  show: (
    reportId: string,
    bindId: string,
    comments: ReportCommentDto[],
    position: DOMRect,
    onSent: () => void,
  ) =>
    DocumentBuilderCommentOverlayEE.emit(
      'show',
      reportId,
      bindId,
      comments,
      position,
      onSent,
    ),
  hide: () => DocumentBuilderCommentOverlayEE.emit('hide'),
};

export default function DocumentBuilderCommentOverlay() {
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [comments, setComments] = React.useState<ReportCommentDto[]>([]);
  const [reportId, setReportId] = React.useState('');
  const [bindId, setBindId] = React.useState('');
  const [position, setPosition] = React.useState<DOMRect>(new DOMRect());
  const onSent = React.useRef<() => void>(() => {
    return;
  });

  const handleShow = (
    reportId: string,
    bindId: string,
    comments: ReportCommentDto[],
    position: DOMRect,
    onSentCallback: () => void,
  ) => {
    setShowOverlay(true);
    setComments(comments);
    setReportId(reportId);
    setBindId(bindId);
    setPosition(position);
    onSent.current = onSentCallback;
  };

  const handleHide = () => {
    setShowOverlay(false);
  };

  React.useEffect(() => {
    DocumentBuilderCommentOverlayEE.on('show', handleShow);
    DocumentBuilderCommentOverlayEE.on('hide', handleHide);
    return () => {
      DocumentBuilderCommentOverlayEE.off('show', handleShow);
      DocumentBuilderCommentOverlayEE.off('hide', handleHide);
    };
  }, []);

  const [content, setContent] = React.useState('');
  const send = async () => {
    await ReportCommentAPI.send(reportId, content, bindId);
    setContent('');
    onSent.current();
  };

  return (
    <div
      className={`document-builder-comment-overlay ${showOverlay && 'shown'}`}
      style={{
        left: `${(position?.left || 0) + (position?.width || 0)}px`,
        top: `${position?.top || 0}px`,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="header">
        <div className="title">评论</div>
        <div className="actions">
          <a href="#" className="action resolve"></a>
          <a href="#" className="action close" onClick={() => handleHide()}></a>
        </div>
      </div>
      <div className="comments">
        {comments.map((v) => (
          <div className="comment" key={`comment-${v.id}`}>
            <div className="user">
              <div className="avatar"></div>
              <span>李王</span>
              {dayjs(v.createdAt).format('YYYY-MM-DD HH:mm')}
              <span>提交评论</span>
            </div>
            <div className="content">{v.content}</div>
          </div>
        ))}
      </div>
      <div className="edit">
        <input
          type="text"
          className="text"
          placeholder="回复"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <a className="send" href="#" onClick={send}></a>
      </div>
    </div>
  );
}
