/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Controller } from '../../controller';
import { ControllerBoundary } from '../../controller/boundary';
import { ControllerEvent } from '../../controller/event';
import { ControllerEventType } from '../../controller/event-type';
import { WidgetSite } from '../../widget/site';
import { ControllerPlugin } from '../plugin.interface';
import './indicator.plugin.scss';

export class ContextMenuIndicatorPlugin implements ControllerPlugin {
  static tryRegister(controller: Controller<any>) {
    if (!controller.isPlugInRegistered(ContextMenuIndicatorPlugin)) {
      controller.registerPlugin(ContextMenuIndicatorPlugin);
    }
  }

  constructor(private readonly __controller: Controller<any>) {}

  private __lastWidget: WidgetSite<any> | null = null;
  private _onContextMenuShow = (
    _controllerEvent: ControllerEvent,
    _initialMouseEvent: MouseEvent,
    _widgetSite: WidgetSite<any>,
    contextMenuWidgetSite: WidgetSite<any>,
  ) => {
    const mark = document.createElement('div');
    mark.className = 'context-menu-indicator-plugin';
    this.__lastWidget = contextMenuWidgetSite;
    this.__controller.mark(
      contextMenuWidgetSite,
      ControllerBoundary.INSIDE,
      mark,
    );
  };

  private _onContextMenuHide = (
    _controllerEvent: ControllerEvent,
    _initialMouseEvent: MouseEvent,
  ) => {
    if (this.__lastWidget) {
      this.__controller.unmark(this.__lastWidget);
    }
  };

  public construct(): void {
    this.__controller.on(
      ControllerEventType.CONTEXTMENUSHOW,
      this._onContextMenuShow,
    );
    this.__controller.on(
      ControllerEventType.CONTEXTMENUHIDE,
      this._onContextMenuHide,
    );
    this.__controller.on(
      ControllerEventType.CONTEXTMENUCLICK,
      this._onContextMenuHide,
    );
  }

  public destruct(): void {
    this.__controller.off(
      ControllerEventType.CONTEXTMENUSHOW,
      this._onContextMenuShow,
    );
    this.__controller.off(
      ControllerEventType.CONTEXTMENUHIDE,
      this._onContextMenuHide,
    );
    this.__controller.off(
      ControllerEventType.CONTEXTMENUCLICK,
      this._onContextMenuHide,
    );
  }
}
