/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Controller } from '../controller';
import { ControllerEvent } from '../controller/event';
import { ControllerEventType } from '../controller/event-type';
import { ContextMenuCapability } from '../plugins/context-menu/context-menu.capability';
import { WidgetCapability } from '../widget/capability';
import { WidgetPortable } from '../widget/portable';
import { WidgetSite } from '../widget/site';
import './text.widget.scss';

export type TextWidgetConfig = {
  bold: boolean;
  center: boolean;
  text: string;
  color: string;
};
export class TextWidget extends WidgetPortable<TextWidgetConfig> {
  protected _capabilities: WidgetCapability[] = [
    new ContextMenuCapability([
      {
        key: 'text-bold',
        label: '粗体',
        onClick: (_e, widgetSite, _key) => {
          widgetSite.config.bold = !widgetSite.config.bold;
          widgetSite.element.style.fontWeight = widgetSite.config.bold
            ? 'bold'
            : 'normal';
          widgetSite.controller.emit(
            new ControllerEvent(ControllerEventType.CHANGED),
            widgetSite,
          );
        },
      },
      {
        key: 'text-center',
        label: '居中',
        onClick: (_e, widgetSite, _key) => {
          widgetSite.config.center = !widgetSite.config.center;
          widgetSite.element.style.textAlign = widgetSite.config.center
            ? 'center'
            : 'left';
          widgetSite.controller.emit(
            new ControllerEvent(ControllerEventType.CHANGED),
            widgetSite,
          );
        },
      },
    ]),
  ];
  public readonly id = 'Text';
  constructor(layer: number, config: Partial<TextWidgetConfig> = {}) {
    super(
      layer,
      Object.assign(
        { bold: false, center: false, text: '', color: 'inherit' },
        config,
      ),
    );
  }

  protected _initContainer(): HTMLElement {
    const element = document.createElement('div');
    element.className = 'text-widget';
    return element;
  }

  protected _initSite(
    widgetSite: WidgetSite<TextWidgetConfig>,
    controller: Controller<any>,
  ): void {
    if (this.layer >= controller.layer) {
      widgetSite.element.contentEditable = 'true';
      widgetSite.element.addEventListener('input', () =>
        this.__onInput(widgetSite),
      );
    }
    if (this.layer >= controller.layer) {
      widgetSite.element.classList.add('current-layer');
    }
  }

  private __onInput(widgetSite: WidgetSite<TextWidgetConfig>) {
    widgetSite.config.text = widgetSite.element.innerText;
    widgetSite.controller.emit(
      new ControllerEvent(ControllerEventType.CHANGED),
      widgetSite,
    );
  }

  public renderSite(widgetSite: WidgetSite<TextWidgetConfig>) {
    widgetSite.element.innerHTML = widgetSite.config.text;
    widgetSite.element.style.color = widgetSite.config.color;
    widgetSite.element.style.fontWeight = widgetSite.config.bold
      ? 'bold'
      : 'normal';
    widgetSite.element.style.textAlign = widgetSite.config.center
      ? 'center'
      : 'left';
    return super.renderSite(widgetSite);
  }
}
