/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Controller } from '../controller';
import { WidgetCapability } from './capability';
import { WidgetSite } from './site';

export abstract class WidgetPortable<T_CONFIG extends object | null | unknown> {
  public abstract readonly id: string;
  constructor(
    public readonly layer: number,
    public readonly initialConfig: T_CONFIG,
    public readonly initialChildren: WidgetPortable<any>[] = [],
  ) {}

  protected abstract _initContainer(): HTMLElement;

  protected abstract _initSite(
    widgetSite: WidgetSite<T_CONFIG>,
    controller: Controller<any>,
  ): void;

  public renderSite(widgetSite: WidgetSite<T_CONFIG>): void {
    widgetSite.children.forEach((v) =>
      widgetSite.element.appendChild(v.element),
    );
  }

  //#region Capabilities

  protected abstract _capabilities: WidgetCapability[];
  public get capabilities(): WidgetCapability[] {
    return [...this._capabilities];
  }

  public capabilityOwn<
    T_CAPABILITY extends { new (...args: any): WidgetCapability },
  >(cap: T_CAPABILITY) {
    return -1 !== this._capabilities.findIndex((v) => v instanceof cap);
  }

  public capabilityGet<
    T_CAPABILITY extends { new (...args: any): WidgetCapability },
  >(cap: T_CAPABILITY): InstanceType<T_CAPABILITY> | undefined {
    return this._capabilities.find(
      (v) => v instanceof cap,
    ) as InstanceType<T_CAPABILITY>;
  }

  //#endregion

  public site(
    parent: WidgetSite<any> | null,
    controller: Controller<any>,
    id: string | null = null,
  ): WidgetSite<T_CONFIG> {
    const site = new WidgetSite(
      controller,
      id || Math.random().toString(36),
      this,
      parent,
      JSON.parse(JSON.stringify(this.initialConfig)),
      this._initContainer(),
      this.initialChildren,
    );
    this._initSite(site, controller);
    site.refresh();
    return site;
  }
}
