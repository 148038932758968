/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import React from 'react';
import AgentSidebar from '../../components/Agent-Sidebar/Agent-Sidebar';
import Header from '../../components/Header/Header';
import './Page.scss';
import { ChatAPI } from '../../api/chat.api';
import { ChatDto } from '../../api/dto/chat.dto';
import ReactMarkdown from 'react-markdown';

export default function AgentPage() {
  const [message, setMessage] = React.useState('');
  const [chats, setChats] = React.useState<ChatDto[]>([]);
  const [sent, setSent] = React.useState(false);
  const messagesRef = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (messagesRef.current) messagesRef.current.scrollTop = 999999;
  }, [chats, messagesRef]);

  React.useEffect(() => {
    document.title = '智能助手 • 背景调查智能系统';
    load();
  }, []);

  const load = async () => {
    const chats = await ChatAPI.getHistory();
    setChats(chats);
  };

  const send = async (text?: string) => {
    const sendingMessage = text || message;
    if (!sendingMessage) return;

    setSent(true);
    const replyId = Date.now();
    const reply = {
      id: replyId,
      role: 'assistant',
      content: ' ',
      isLoading: true,
    };
    const userSent: ChatDto = {
      id: replyId + 1,
      role: 'user',
      content: sendingMessage,
    };
    setChats([...chats, userSent, reply]);
    if (!text) {
      setMessage('');
    }

    const chatRequest = await ChatAPI.chat(sendingMessage);

    const chat = chatRequest.body?.getReader();

    let chunk: ReadableStreamReadResult<Uint8Array> | null = null;
    const newChats: ChatDto[] = [];
    let buffer = '';
    while ((chunk = (await chat?.read()) || null)) {
      buffer += new TextDecoder().decode(chunk.value);
      const chunkStrings = buffer.split('\n\n');
      if (!chunk.done) buffer = chunkStrings.pop() || '';

      const chunkObjects = chunkStrings
        .filter((v) => v)
        .map((v) => JSON.parse(v));

      for (const chunkObject of chunkObjects) {
        if (
          'assistant' === chunkObject.choices[0].delta.role &&
          !isNaN(parseInt(chunkObject.id))
        ) {
          reply.id = parseInt(chunkObject.id);
        }
        reply.content += chunkObject.choices[0].delta.content || '';
      }

      reply.isLoading = !chunk.done;
      setChats([...chats, userSent, reply, ...newChats]);

      if (chunk.done) {
        break;
      }
    }
  };

  return (
    <div className="agent-page">
      <Header />
      <div className="body">
        <AgentSidebar />
        <div className="chat">
          <div className="history">
            <div className="heading">
              <div className="icon"></div>
              <div className="label">AI 智能助手</div>
            </div>
            <div className="messages" ref={messagesRef}>
              {chats
                ?.filter((v) => v.content)
                .map((v) => {
                  if (!v.content) return;
                  switch (v.role) {
                    case 'tool':
                      return;
                    case 'user':
                    case 'assistant':
                    default:
                      return (
                        <div
                          className={`message ${
                            'user' === v.role ? 'sender' : 'receiver'
                          } ${v.isLoading && 'loading'}`}
                          key={`message-${v.id}`}
                        >
                          <div className="avatar"></div>
                          <div className="bubble">
                            <ReactMarkdown
                              components={{
                                // eslint-disable-next-line no-unused-vars
                                a: ({ node, children, ...props }) => {
                                  if (props.href?.includes('http')) {
                                    props.target = '_blank';
                                    props.rel = 'noopener noreferrer';
                                  }
                                  return <a {...props}>{children}</a>;
                                },
                              }}
                            >
                              {v.content}
                            </ReactMarkdown>
                          </div>
                        </div>
                      );
                  }
                })}
              <div
                className={`placeholder ${0 != chats.length && 'compact'}`}
                hidden={0 != chats.length && sent}
              >
                <div className="text">
                  您好，我是您的智能AI助手
                  <br />
                  请问有什么可以帮到您？
                </div>
                <div className="links">
                  <div className="link" onClick={() => send('汇总人员关系')}>
                    <div className="title">人员关系图谱</div>
                    <div className="description">
                      我可以为您整理人员关系图谱，梳理不同人选之间的关系
                    </div>
                  </div>
                  <div className="link" onClick={() => send('了解订单详情')}>
                    <div className="title">了解订单详情</div>
                    <div className="description">
                      我可以为您快速汇总不同订单的进展情况，并回答有关问题
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="input">
            <div className="avatar"></div>
            <input
              type="text"
              className="text"
              placeholder="您想说些什么..."
              value={message}
              onInput={(e) => setMessage(e.currentTarget.value)}
              onKeyDown={(e) => 'Enter' == e.key && send()}
            />
            <a href="#" className="send" onClick={() => send()}></a>
          </div>
        </div>
      </div>
    </div>
  );
}
