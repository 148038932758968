/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import React from 'react';
import Header from '../../components/Header/Header';
import './Page.scss';
import DataSidebar from '../../components/Data-Sidebar/Data-Sidebar';
import { Button, Table } from 'antd';
import { CandidateAPI } from '../../api/candidate.api';
import { CandidateDto } from '../../api/dto/candidate.dto';
import DataIdentityOverlay, {
  DataIdentityOverlayController,
} from '../../components/Data-Identity-Overlay/Data-Identity-Overlay';
import DataEducationOverlay, {
  DataEducationOverlayController,
} from '../../components/Data-Education-Overlay/Data-Education-Overlay';
import DataWorkOverlay, {
  DataWorkOverlayController,
} from '../../components/Data-Work-Overlay/Data-Work-Overlay';
import { TrailOverlayController } from '../../components/Trail-Overlay/Trail-Overlay';
import dayjs from 'dayjs';

export default function DataPage() {
  const [candidates, setCandidates] = React.useState<CandidateDto[]>([]);
  React.useEffect(() => {
    document.title = '数据管理 • 背景调查智能系统';
    load();
  }, []);

  const load = async () => {
    setCandidates(await CandidateAPI.list());
  };

  return (
    <div className="data-page">
      <Header />
      <DataIdentityOverlay />
      <DataEducationOverlay />
      <DataWorkOverlay />
      <div className={`body`}>
        <DataSidebar />
        <main>
          <div className="title">
            <div className="text">数据管理</div>
            <a
              href="#"
              className="add"
              onClick={() => {
                TrailOverlayController.show();
                load();
              }}
            ></a>
          </div>
          <div className="table">
            <Table
              dataSource={candidates}
              rowKey="id"
              columns={[
                {
                  title: '人选',
                  dataIndex: ['identityBackground', 'name'],
                },
                {
                  title: '公司',
                  render: (v: CandidateDto) => {
                    return v.orders[0].company.name;
                  },
                },
                {
                  title: '更新日期',
                  render: (v: CandidateDto) => {
                    return dayjs(v.updatedAt).format('YYYY-MM-DD HH:mm');
                  },
                },
                {
                  title: '管理',
                  dataIndex: 'id',
                  width: 400,
                  render: (v) => {
                    return (
                      <>
                        <Button
                          type="link"
                          onClick={() =>
                            CandidateAPI.getById(v).then((r) =>
                              DataIdentityOverlayController.show(
                                r.id,
                                r.identityBackground,
                                load,
                              ),
                            )
                          }
                        >
                          身份数据
                        </Button>
                        <Button
                          type="link"
                          onClick={() =>
                            CandidateAPI.getById(v).then((r) =>
                              DataEducationOverlayController.show(
                                r.educationBackground[0],
                                load,
                              ),
                            )
                          }
                        >
                          教育背景数据
                        </Button>
                        <Button
                          type="link"
                          onClick={() =>
                            CandidateAPI.getById(v).then((r) =>
                              DataWorkOverlayController.show(
                                r.workBackground,
                                load,
                              ),
                            )
                          }
                        >
                          工作履历数据
                        </Button>
                      </>
                    );
                  },
                },
              ]}
            ></Table>
          </div>
        </main>
      </div>
    </div>
  );
}
