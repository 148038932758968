/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import React from 'react';
import Overlay from '../Overlay/Overlay';
import './Notification-Overlay.scss';
import EventEmitter from 'eventemitter3';

const NotificationOverlayEE = new EventEmitter();

export const NotificationOverlayController = {
  show: () => NotificationOverlayEE.emit('show'),
  hide: () => NotificationOverlayEE.emit('hide'),
};

export default function NotificationOverlay() {
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [read, setRead] = React.useState<number[]>(
    JSON.parse(localStorage.getItem('read-notifications') || '[4,7]'),
  );

  React.useEffect(() => {
    localStorage.setItem('read-notifications', JSON.stringify(read));
  }, [read]);

  const handleShow = () => {
    setShowOverlay(true);
  };

  const handleHide = () => {
    setShowOverlay(false);
  };

  const [checked, setChecked] = React.useState<number[]>([]);
  const toggleChecked = (i: number) => {
    const rawChecked = checked.filter((v) => v !== i);
    if (rawChecked.length === checked.length) {
      rawChecked.push(i);
    }
    setChecked(rawChecked);
  };

  React.useEffect(() => {
    NotificationOverlayEE.on('show', handleShow);
    NotificationOverlayEE.on('hide', handleHide);
    return () => {
      NotificationOverlayEE.off('show', handleShow);
      NotificationOverlayEE.off('hide', handleHide);
    };
  }, []);

  return (
    <Overlay
      hidden={!showOverlay}
      onBackClick={() => NotificationOverlayController.hide()}
    >
      <div className="notification-overlay">
        <div className="header">
          <div className="legend">消息通知</div>
          <div className="search">
            <input type="text" className="text" placeholder="搜索信息" />
            <a href="#" className="button"></a>
          </div>
        </div>
        <div className="tabbar">
          <div className="filters">
            <div className="filter active">全部</div>
            <div className="filter">未读</div>
            <div className="filter">已读</div>
          </div>

          <div className="actions">
            <a
              href="#"
              className="action"
              onClick={() => setRead([0, 1, 2, 3, 4, 5, 6, 7])}
            >
              全部记为已读
            </a>
            <a
              href="#"
              className="action active"
              onClick={() =>
                setRead(Array.from(new Set([...read, ...checked])))
              }
            >
              记为已读
            </a>
          </div>
        </div>
        <div className="notifications">
          <div
            className={`notification ${checked.includes(0) && 'checked'} ${read.includes(0) && 'read'}`}
          >
            <div className="checkbox" onClick={() => toggleChecked(0)}></div>
            <div className="type">报告审核</div>
            <div className="name">[李承安] 报告待审核</div>
            <div className="datetime">
              <div className="date">2024-04-11</div>
              <div className="time">12:00:00</div>
            </div>
          </div>
          <div
            className={`notification ${checked.includes(1) && 'checked'} ${read.includes(1) && 'read'}`}
          >
            <div className="checkbox" onClick={() => toggleChecked(1)}></div>
            <div className="type">报告评论</div>
            <div className="name">[李承安] 报告有新的评论</div>
            <div className="datetime">
              <div className="date">2024-04-10</div>
              <div className="time">09:00:00</div>
            </div>
          </div>
          <div
            className={`notification ${checked.includes(2) && 'checked'} ${read.includes(2) && 'read'}`}
          >
            <div className="checkbox" onClick={() => toggleChecked(2)}></div>
            <div className="type">订单状态</div>
            <div className="name">[张赫] 订单已创建</div>
            <div className="datetime">
              <div className="date">2024-04-01</div>
              <div className="time">18:00:00</div>
            </div>
          </div>
          <div
            className={`notification ${checked.includes(3) && 'checked'} ${read.includes(3) && 'read'}`}
          >
            <div className="checkbox" onClick={() => toggleChecked(3)}></div>
            <div className="type">订单状态</div>
            <div className="name">[黎明] 订单完结</div>
            <div className="datetime">
              <div className="date">2024-03-25</div>
              <div className="time">18:00:00</div>
            </div>
          </div>
          <div
            className={`notification ${checked.includes(4) && 'checked'} ${read.includes(4) && 'read'}`}
          >
            <div className="checkbox" onClick={() => toggleChecked(4)}></div>
            <div className="type">报告审核</div>
            <div className="name">[李承安] 报告有新的评论</div>
            <div className="datetime">
              <div className="date">2024-03-11</div>
              <div className="time">18:00:00</div>
            </div>
          </div>
          <div
            className={`notification ${checked.includes(5) && 'checked'} ${read.includes(5) && 'read'}`}
          >
            <div className="checkbox" onClick={() => toggleChecked(5)}></div>
            <div className="type">订单状态</div>
            <div className="name">[鹤临] 订单已创建</div>
            <div className="datetime">
              <div className="date">2024-03-05</div>
              <div className="time">18:00:00</div>
            </div>
          </div>
          <div
            className={`notification ${checked.includes(6) && 'checked'} ${read.includes(6) && 'read'}`}
          >
            <div className="checkbox" onClick={() => toggleChecked(6)}></div>
            <div className="type">订单状态</div>
            <div className="name">[史上] 订单已创建</div>
            <div className="datetime">
              <div className="date">2024-03-01</div>
              <div className="time">18:00:00</div>
            </div>
          </div>
          <div
            className={`notification ${checked.includes(7) && 'checked'} ${read.includes(7) && 'read'}`}
          >
            <div className="checkbox" onClick={() => toggleChecked(7)}></div>
            <div className="type">报告评论</div>
            <div className="name">[李行中] 报告有新的评论</div>
            <div className="datetime">
              <div className="date">2024-02-21</div>
              <div className="time">18:00:00</div>
            </div>
          </div>
        </div>
      </div>
    </Overlay>
  );
}
