/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Controller } from '../controller';
import { ControllerEvent } from '../controller/event';
import { ControllerEventType } from '../controller/event-type';
import { WidgetDump } from './dump';
import { WidgetPortable } from './portable';

export class WidgetSite<T extends object | null | unknown> {
  public readonly children: WidgetSite<any>[] = [];
  constructor(
    public readonly controller: Controller<any>,
    public readonly id: string,
    public readonly portable: WidgetPortable<T>,
    public parent: WidgetSite<any> | null = null,
    public readonly config: T,
    public readonly element: HTMLElement,
    initialChildren: WidgetPortable<any>[],
  ) {
    this.children.push(
      ...initialChildren.map((v) => v.site(this, this.controller)),
    );
    this.refresh();
  }

  public clear() {
    this.children.splice(0, this.children.length);
    this.refresh();
  }

  public refresh() {
    this.element.innerHTML = '';
    this.portable.renderSite(this);
    this.children.forEach((v) => v.refresh());
  }

  public findWidgetByElement(
    element: HTMLElement,
    layer: number,
  ): WidgetSite<any> | null {
    if (this.element === element) {
      return layer <= this.portable.layer ? this : null;
    }
    for (const child of this.children) {
      const result = child.findWidgetByElement(element, layer);
      if (result) return result;
    }
    return null;
  }

  public findWidgetById(id: string): WidgetSite<any> | null {
    if (this.id === id) {
      return this;
    }
    for (const child of this.children) {
      const result = child.findWidgetById(id);
      if (result) return result;
    }
    return null;
  }

  public append(portable: WidgetPortable<unknown>) {
    const newWidget = portable.site(this, this.controller);
    this.children.push(newWidget);
    this.element.appendChild(newWidget.element);
    this.controller.emit(
      new ControllerEvent(ControllerEventType.CHANGED),
      this,
    );
    this.controller.emit(
      new ControllerEvent(ControllerEventType.CHILDAPPENDED),
      this,
      newWidget,
    );
  }

  public before(portable: WidgetPortable<unknown>, before: WidgetSite<any>) {
    const newWidget = portable.site(this, this.controller);
    const beforeIndex = this.children.indexOf(before);
    if (-1 === beforeIndex) {
      throw new Error('Widget not found');
    }
    this.children.splice(beforeIndex, 0, newWidget);
    this.element.insertBefore(newWidget.element, before.element);
    this.controller.emit(
      new ControllerEvent(ControllerEventType.CHANGED),
      this,
    );
    this.controller.emit(
      new ControllerEvent(ControllerEventType.CHILDBEFORE),
      this,
      newWidget,
      before,
    );
  }

  public after(portable: WidgetPortable<unknown>, after: WidgetSite<any>) {
    const newWidget = portable.site(this, this.controller);
    const afterIndex = this.children.indexOf(after);
    if (-1 === afterIndex) {
      throw new Error('Widget not found');
    }
    this.children.splice(afterIndex + 1, 0, newWidget);
    this.element.insertBefore(newWidget.element, after.element.nextSibling);
    this.controller.emit(
      new ControllerEvent(ControllerEventType.CHANGED),
      this,
    );
    this.controller.emit(
      new ControllerEvent(ControllerEventType.CHILDAFTER),
      this,
      newWidget,
      after,
    );
  }

  public ancestors() {
    const foundAncestors: WidgetSite<any>[] = [this];
    let search = this.parent;
    while (search) {
      foundAncestors.push(search);
      search = search.parent;
    }
    return foundAncestors;
  }

  public remove(site: WidgetSite<any>) {
    const index = this.children.indexOf(site);
    if (-1 === index) return;
    this.children.splice(index, 1);
    this.element.removeChild(site.element);
    this.controller.emit(
      new ControllerEvent(ControllerEventType.CHANGED),
      this,
    );
    this.controller.emit(
      new ControllerEvent(ControllerEventType.REMOVED),
      this,
      site,
    );
  }

  public toJSON(): WidgetDump {
    return {
      id: this.id,
      portable: this.portable.id,
      layer: this.portable.layer,
      config: this.config,
      children: this.children.map((v) => v.toJSON()),
    };
  }
}
