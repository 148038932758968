/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { ControllerEvent } from '../controller/event';
import { ContextMenuCapability } from '../plugins/context-menu/context-menu.capability';
import { WidgetCapability } from '../widget/capability';
import { WidgetPortable } from '../widget/portable';
import { WidgetSite } from '../widget/site';
import { TableCellWidget } from './table-cell.widget';
// import { TableWidgetConfig } from './table.widget';

export type TableRowWidgetConfig = null;
export class TableRowWidget extends WidgetPortable<TableRowWidgetConfig> {
  protected _capabilities: WidgetCapability[] = [
    new ContextMenuCapability([
      {
        key: 'table-row-delete',
        label: '删除整行',
        onClick: (
          _controllerEvent: ControllerEvent,
          widgetSite: WidgetSite<any>,
        ) => {
          widgetSite.parent?.remove(widgetSite);
        },
      },
      {
        key: 'table-row-add-prev',
        label: '在上方添加一行',
        onClick: (
          _controllerEvent: ControllerEvent,
          widgetSite: WidgetSite<any>,
        ) => {
          widgetSite.parent?.before(
            new TableRowWidget(
              this.layer,
              widgetSite.children.map((_v) => new TableCellWidget(this.layer)),
            ),
            widgetSite,
          );
        },
      },
      {
        key: 'table-row-add-next',
        label: '在下方添加一行',
        onClick: (
          _controllerEvent: ControllerEvent,
          widgetSite: WidgetSite<any>,
        ) => {
          widgetSite.parent?.after(
            new TableRowWidget(
              this.layer,
              widgetSite.children.map((_v) => new TableCellWidget(this.layer)),
            ),
            widgetSite,
          );
        },
      },
    ]),
  ];
  public readonly id = 'TableRow';
  constructor(layer: number, children: WidgetPortable<any>[] = []) {
    super(layer, null, children);
  }

  protected _initContainer(): HTMLElement {
    const element = document.createElement('tr');
    element.className = 'table-row-widget';
    return element;
  }

  protected _initSite(site: WidgetSite<null>): void {
    if (0 === this.initialChildren.length) {
      site.append(new TableCellWidget(this.layer));
    }
  }

  public renderSite(widgetSite: WidgetSite<null>): void {
    for (let i = 0; i < widgetSite.children.length; i++) {
      const child = widgetSite.children[i];
      widgetSite.element.appendChild(child.element);
      if (1 < child.config.colSpan) {
        i += child.config.colSpan - 1;
      }
    }
    return;
  }
}
