/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { HttpAPI } from './http.api';
import Cookies from 'js-cookie';
import { decode } from 'js-base64';
import { UserDto } from './dto/user.dto';

export class AuthAPI {
  static getLocalUser(): UserDto | null {
    const token = Cookies.get('access-token');
    if (!token) return null;
    return JSON.parse(decode(token.split('.')[1])).webUser;
  }

  static ping(token?: string, role?: string): Promise<UserDto> {
    return HttpAPI.get(
      Cookies.get('access-token') || token || '',
      role ? `auth/by-role/${role.toLowerCase()}` : `auth`,
    );
  }

  static async signIn(
    username: string,
    password: string,
    shouldRemember: boolean,
  ) {
    const metadata = await HttpAPI.get(
      null,
      `auth/sign-in/${encodeURIComponent(username)}/${encodeURIComponent(password)}`,
      {},
      true,
    );

    if (shouldRemember) {
      Cookies.set('access-token', Cookies.get('access-token') || '', {
        expires: 365,
      });
    }

    return metadata;
  }

  static async signOff() {
    await HttpAPI.delete(Cookies.get('access-token') || '', `auth`);
    Cookies.remove('access-token');
  }
}
