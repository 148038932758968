/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import Cookies from 'js-cookie';
import { IdentityBackgroundDto } from './dto/identity-background.dto';
import { HttpAPI } from './http.api';

export class IdentityBackgroundAPI {
  static updateById(id: string, data: Partial<IdentityBackgroundDto>) {
    return HttpAPI.patch(
      Cookies.get('access-token') || '',
      `identity-background/by-id/${id}`,
      data,
    );
  }
}
