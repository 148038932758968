/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import './Document-Builder-Library.scss';
import { WidgetAPI } from '../../api/widget.api';
import React from 'react';
import { Controller } from '../../pages/Unified-Designer/lib/controller';
import { WidgetDto } from '../../api/dto/widget.dto';
import { DragBasePlugin } from '../../pages/Unified-Designer/lib/plugins/drag-drop/base.plugin';
import { DumpWrapperWidget } from '../../pages/Unified-Designer/lib/widgets/dump-wrapper.widget';
import { ReportDto } from '../../api/dto/report.dto';
import DocumentBuilderReviewOverlay, {
  DocumentBuilderReviewOverlayController,
} from '../Document-Builder-Review-Overlay/Document-Builder-Review-Overlay';
import DocumentBuilderReviewAcceptOverlay, {
  DocumentBuilderReviewAcceptOverlayController,
} from '../Document-Builder-Review-Accept-Overlay/Document-Builder-Review-Accept-Overlay';
import { TrailOverlayController } from '../Trail-Overlay/Trail-Overlay';

export default function DocumentBuilderLibrary({
  controller,
  report,
  onReview,
  onAccept,
  onDownload,
}: {
  controller: Controller<any>;
  report?: ReportDto;
  onReview: () => void;
  onAccept: () => void;
  onDownload: () => void;
}) {
  const [tocFolded, setTocFolded] = React.useState(true);
  const [widgets, setWidgets] = React.useState<
    { name: string; widgets: WidgetDto[] }[]
  >([]);

  const load = async () => {
    const widgets: { name: string; widgets: WidgetDto[] }[] = [];
    const res = await WidgetAPI.list();

    res.map((w) => {
      if (-1 === widgets.findIndex((v) => v.name === w.group)) {
        widgets.push({ name: w.group, widgets: [] });
      }

      widgets
        .find((v) => v.name === w.group)
        ?.widgets.push({
          id: w.id,
          name: w.name,
          icon: w.icon,
          definition: w.definition,
          group: w.group,
        });
      setWidgets(widgets);
    });
  };

  React.useEffect(() => {
    load();
  }, []);

  const review = () => {
    if (!report) return;
    DocumentBuilderReviewOverlayController.show(report.id, () => {
      onReview?.();
    });
  };

  const accept = () => {
    if (!report) return;
    DocumentBuilderReviewAcceptOverlayController.show(report.id, () => {
      onAccept?.();
    });
  };

  return (
    <div className="document-builder-library">
      <DocumentBuilderReviewOverlay />
      <DocumentBuilderReviewAcceptOverlay />
      <div className="search">
        <div className="icon"></div>
        <input type="text" className="text" placeholder="模块搜索......" />
        <div className={`toc ${!tocFolded && 'unfold'}`}>
          <div className="label" onClick={() => setTocFolded(!tocFolded)}>
            目录
          </div>
        </div>
      </div>
      {widgets.map((v) => (
        <div className="group" key={v.name}>
          <div className="label">
            <div className="text">{v.name}</div>
          </div>
          <div className="widgets">
            {v.widgets.map((w) => (
              <div
                className="widget"
                onMouseDown={(e) => {
                  if (w.definition && Object.keys(w.definition).length) {
                    controller
                      .getPlugIn(DragBasePlugin)
                      ?.drag(
                        new DumpWrapperWidget(1, { dump: w.definition }),
                        e.nativeEvent,
                      );
                  } else {
                    e.preventDefault();
                    TrailOverlayController.show();
                  }
                }}
                key={`widget-${w.id}`}
              >
                <img className="icon" src={w.icon} />
                <div className="name">{w.name}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className="links">
        {null === report?.reviewed && (
          <a href="#" className="link submit" onClick={review}>
            提交审核
          </a>
        )}
        {false === report?.reviewed && (
          <a href="#" className="link submit" onClick={accept}>
            通过审核
          </a>
        )}
        {report?.reviewed && (
          <a href="#" className="link submit" onClick={onDownload}>
            下载报告
          </a>
        )}
        <a href="#" className="link view">
          查看评论
        </a>
      </div>
    </div>
  );
}
