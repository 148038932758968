/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import Cookies from 'js-cookie';
import { HttpAPI } from './http.api';
import { ReportCommentDto } from './dto/report-comment.dto';

export class ReportCommentAPI {
  static send(reportId: string, content: string, bindId: string) {
    return HttpAPI.post(Cookies.get('access-token') || '', `report-comment`, {
      reportId,
      content,
      bindId,
    });
  }
  static listByReport(reportId: string): Promise<ReportCommentDto[]> {
    return HttpAPI.get(
      Cookies.get('access-token') || '',
      `report-comment/by-report/${reportId}`,
    );
  }
}
