/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import React from 'react';
import { AuthAPI } from '../../api/auth.api';
import './Template-Sidebar.scss';
import SidebarSchedule from '../Sidebar-Schedule/Sidebar-Schedule';

export default function TemplateSidebar() {
  const user = AuthAPI.getLocalUser();
  return (
    <div className="template-sidebar-component">
      <div className="user">
        <div className="avatar"></div>
        <div className="info">
          <div className="name">{user?.name}</div>
          <div className="role">{user?.title}</div>
        </div>
        <div className="dropdown"></div>
      </div>
      <div className="nav">
        <div className="group">
          <a href="/template-builder" className="link initial active">
            <div className="icon"></div>
            <div className="text">初始模板</div>
            <div className="counter"></div>
          </a>
          <a href="#" className="link mine">
            <div className="icon"></div>
            <div className="text">我的模板</div>
            <div className="counter"></div>
          </a>
          <a href="#" className="link share">
            <div className="icon"></div>
            <div className="text">共享模版</div>
            <div className="counter"></div>
          </a>
        </div>
        <div className="separator"></div>
        <div className="group">
          <a href="#" className="link import">
            <div className="icon"></div>
            <div className="text">导入模版</div>
            <div className="counter"></div>
          </a>
        </div>
      </div>
      <SidebarSchedule />
    </div>
  );
}
