/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Controller } from '../../controller';
import { ControllerBoundary } from '../../controller/boundary';
import { ControllerEvent } from '../../controller/event';
import { ControllerEventType } from '../../controller/event-type';
import { WidgetPortable } from '../../widget/portable';
import { WidgetSite } from '../../widget/site';
import { ControllerPlugin } from '../plugin.interface';
import './indicator.plugin.scss';

export class DragIndicatorPlugin implements ControllerPlugin {
  static tryRegister(controller: Controller<any>) {
    if (!controller.isPlugInRegistered(DragIndicatorPlugin)) {
      controller.registerPlugin(DragIndicatorPlugin);
    }
  }

  constructor(private readonly __controller: Controller<any>) {}

  private _onDragOver = (
    _controllerEvent: ControllerEvent,
    _initialMouseEvent: MouseEvent,
    _widgetPortable: WidgetPortable<any>,
    droppableWidgetSite: WidgetSite<any>,
    boundaryWidgetSite: WidgetSite<any>,
    boundary: ControllerBoundary,
  ) => {
    const mark = document.createElement('div');
    mark.className = 'drag-drop-indicator-plugin';
    this.__controller.mark(
      boundaryWidgetSite || droppableWidgetSite,
      boundary,
      mark,
    );
  };

  private _onDragOut = (
    _controllerEvent: ControllerEvent,
    _initialMouseEvent: MouseEvent,
    _widgetPortable: WidgetPortable<any>,
    droppableWidgetSite: WidgetSite<any>,
    boundaryWidgetSite: WidgetSite<any>,
  ) => {
    this.__controller.unmark(boundaryWidgetSite || droppableWidgetSite);
  };

  public construct(): void {
    this.__controller.on(ControllerEventType.DRAGOVER, this._onDragOver);
    this.__controller.on(ControllerEventType.DRAGOUT, this._onDragOut);
  }

  public destruct(): void {
    this.__controller.off(ControllerEventType.DRAGOVER, this._onDragOver);
    this.__controller.off(ControllerEventType.DRAGOUT, this._onDragOut);
  }
}
