/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { WidgetPortable } from '../widget/portable';
import { WidgetSite } from '../widget/site';
import { ControllerBoundary } from './boundary';
import { ControllerEvent } from './event';

export enum ControllerEventType {
  RESIZE = 'resize',
  SCROLL = 'scroll',
  MOUSEUP = 'mouseup',
  MOUSEOVER = 'mouseover',
  MOUSEDOWN = 'mousedown',
  DRAG = 'drag',
  DRAGOVER = 'dragover',
  DRAGOUT = 'dragout',
  DRAGGING = 'dragging',
  DROP = 'drop',
  CONTEXTMENUSHOW = 'contextmenushow',
  CONTEXTMENUHIDE = 'contextmenuhide',
  CONTEXTMENUCLICK = 'contextmenuclick',
  CHILDAPPENDED = 'childappended',
  CHILDBEFORE = 'childbefore',
  CHILDAFTER = 'childafter',
  CHANGED = 'changed',
  REMOVED = 'removed',
  COMMENT = 'comment',
  COMMENTPINCLICK = 'commentpinclick',
}

export type ControllerEventTypeCallbackType<T> = T extends ControllerEventType
  ? ControllerEventTypeCallback[T]
  : (...a: [ControllerEvent, ...any]) => void;

export interface ControllerEventTypeCallback {
  [ControllerEventType.RESIZE]: (event: ControllerEvent) => void;
  [ControllerEventType.SCROLL]: (event: ControllerEvent) => void;
  [ControllerEventType.MOUSEUP]: (
    event: ControllerEvent,
    mouseEvent: MouseEvent,
  ) => void;
  [ControllerEventType.MOUSEOVER]: (
    event: ControllerEvent,
    mouseEvent: MouseEvent,
  ) => void;
  [ControllerEventType.MOUSEDOWN]: (
    event: ControllerEvent,
    mouseEvent: MouseEvent,
  ) => void;
  [ControllerEventType.DRAG]: (
    event: ControllerEvent,
    mouseEvent: MouseEvent,
    widgetPortable: WidgetPortable<unknown>,
  ) => void;
  [ControllerEventType.DRAGOVER]: (
    event: ControllerEvent,
    mouseEvent: MouseEvent,
    widgetPortable: WidgetPortable<unknown>,
    droppableWidgetSite: WidgetSite<any>,
    boundaryWidgetSite: WidgetSite<any>,
    boundary: ControllerBoundary,
  ) => void;
  [ControllerEventType.DRAGOUT]: (
    event: ControllerEvent,
    mouseEvent: MouseEvent,
    widgetPortable: WidgetPortable<unknown>,
    droppableWidgetSite: WidgetSite<any>,
    boundaryWidgetSite: WidgetSite<any>,
  ) => void;
  [ControllerEventType.DROP]: (
    event: ControllerEvent,
    mouseEvent: MouseEvent,
    widgetPortable: WidgetPortable<unknown>,
    droppableWidgetSite: WidgetSite<any>,
    boundary: ControllerBoundary | null,
    boundaryWidgetSite: WidgetSite<any> | null,
  ) => void;
  [ControllerEventType.DRAGGING]: (
    event: ControllerEvent,
    mouseEvent: MouseEvent,
    widgetPortable: WidgetPortable<unknown>,
  ) => void;
  [ControllerEventType.CONTEXTMENUSHOW]: (
    event: ControllerEvent,
    mouseEvent: MouseEvent,
    widgetSite: WidgetSite<any>,
    contextMenuWidgetSite: WidgetSite<any>,
  ) => void;
  [ControllerEventType.CONTEXTMENUHIDE]: (
    event: ControllerEvent,
    mouseEvent: MouseEvent,
  ) => void;
  [ControllerEventType.CONTEXTMENUCLICK]: (
    event: ControllerEvent,
    mouseEvent: MouseEvent,
    widgetSite: WidgetSite<any>,
    key: string,
  ) => void;
  [ControllerEventType.CHILDAPPENDED]: (
    event: ControllerEvent,
    parent: WidgetSite<any>,
    child: WidgetSite<any>,
  ) => void;
  [ControllerEventType.CHILDBEFORE]: (
    event: ControllerEvent,
    parent: WidgetSite<any>,
    child: WidgetSite<any>,
    before: WidgetSite<any>,
  ) => void;
  [ControllerEventType.CHILDAFTER]: (
    event: ControllerEvent,
    parent: WidgetSite<any>,
    child: WidgetSite<any>,
    after: WidgetSite<any>,
  ) => void;
  [ControllerEventType.CHANGED]: (
    event: ControllerEvent,
    widget: WidgetSite<any>,
  ) => void;
  [ControllerEventType.REMOVED]: (
    event: ControllerEvent,
    parent: WidgetSite<any>,
    child: WidgetSite<any>,
  ) => void;
  [ControllerEventType.COMMENT]: (
    event: ControllerEvent,
    mouseEvent: MouseEvent,
    target: WidgetSite<any>,
  ) => void;
  [ControllerEventType.COMMENTPINCLICK]: (
    event: ControllerEvent,
    mouseEvent: MouseEvent,
    target: WidgetSite<any>,
  ) => void;
}
