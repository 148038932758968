/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import React from 'react';
import './Header.scss';
import NotificationOverlay, {
  NotificationOverlayController,
} from '../Notification-Overlay/Notification-Overlay';

export default function Header() {
  return (
    <div className="header-component">
      <NotificationOverlay />
      <div className="legend">
        <div className="icon"></div>
        <div className="text">背景调查智能系统</div>
      </div>
      <div className="nav">
        <a
          href="/orders"
          className={`link ${location.pathname.startsWith('/orders') && 'active'}`}
        >
          背调订单
        </a>
        <a
          href="/data"
          className={`link ${location.pathname.startsWith('/data') && 'active'}`}
        >
          背调数据
        </a>
        <a
          href="/document-builder"
          className={`link ${location.pathname.startsWith('/document-builder') && 'active'}`}
        >
          报告制作
        </a>
        <a
          href="/template-builder"
          className={`link ${location.pathname.startsWith('/template-builder') && 'active'}`}
        >
          报告模版
        </a>
        <a
          href="/agent"
          className={`link ${location.pathname.startsWith('/agent') && 'active'}`}
        >
          智能助手
        </a>
      </div>
      <div className="user">
        <a
          className="notification"
          href="#"
          onClick={() => NotificationOverlayController.show()}
        ></a>
        <div className="avatar"></div>
      </div>
    </div>
  );
}
